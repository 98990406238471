// refunds

export default {
  title: 'Refund rules',

  timeLabels: {
    all: 'unlimited',
    from: 'From {time} hours left to time to start time',
    fromTo: 'From {from} to {to} hours left to time',
    to: 'Up to {time} hours left to time'
  },

  notifications: {
    wrongValue: 'The price of the {name} time has not been entered correctly',

    edit: {
      success: 'Refund rules information successfully edited',
      error: 'There was an error editing the refund rules information'
    }
  },

  validators: {
    percentRange: 'The percentage must be between 0 and 100!',
    value: 'The percentage must be greater than or equal to the values of the higher fields'
  },

  list: {
    title: 'Refund rules',

    actions: {
      invoice: 'Invoice refund',
      saleAddon: 'Additional service refund',
      coach: 'Coach refund'
    }
  },

  edit: {
    title: '',
    dynamicTitle: 'Edit {name}'
  },

  profile: {
    table: {
      header: {
        refund: 'Percent of refund',
        time: 'Time'
      }
    }
  }
}