export default {
  path: 'season-schedules',
  component: () => import('../../../views/admin/sales/invoices/index.vue'),
  children: [
    {
      path: '/',
      name: 'seasonSchedules',
      component: () => import('../../../views/admin/sales/seasonSchedules/list/seasonSchedulesList.vue'),
      meta: {
        permission: 'season_scheduler.show',
        pageTitle: 'رزرو تایم',
        pageTitleI18n: 'sales.seasonSchedules.list.title'
        // rule: 'editor'
      }
    },
    {
      path: 'trash',
      name: 'seasonSchedulesTrash',
      component: () => import('../../../views/admin/sales/invoices/trash/saleInvoiceTrash.vue'),
      meta: {
        permission: 'season_scheduler.delete',
        pageTitle: 'سطل زباله لیست رزرو',
        pageTitleI18n: ''
        // rule: 'editor'
      }
    },
    {
      path: 'trash/:id',
      name: 'seasonScheduleTrash',
      component: () => import('../../../views/admin/sales/invoices/invoice/saleInvoice.vue'),
      meta: {
        permission: 'season_scheduler.delete',
        pageTitle: 'فاکتور رزرو حذف شده',
        pageTitleI18n: ''
        // rule: 'editor'
      }
    },
    {
      path: 'insert',
      name: 'insertSeasonScheduleInvoice',
      component: () => import('../../../views/admin/sales/seasonSchedules/insert/insertTimeSeasonScheduleInvoice.vue'),
      meta: {
        permission: 'season_scheduler.create',
        pageTitle: 'ثبت فاکتور رزرو فصلی',
        pageTitleI18n: 'sales.seasonSchedules.insert.title'
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      name: 'seasonSchedule',
      component: () => import('../../../views/admin/sales/seasonSchedules/invoice/seasonScheduleInvoice.vue'),
      meta: {
        permission: 'season_scheduler.show',
        pageTitle: 'فاکتور رزرو فصلی',
        pageTitleI18n: 'sales.seasonSchedules.invoice.title'
        // rule: 'editor'
      }
    },
    // {
    //   path: ':id/edit',
    //   name: 'editSaleInvoice',
    //   component: () => import('../../../views/admin/sales/invoices/edit/editSaleInvoice.vue'),
    //   meta: {
    //     permission: 'invoice.update',
    //     pageTitle: 'ویرایش فاکتور فروش',
    //     pageTitleI18n: 'sales.invoices.edit.title'
    //     // rule: 'editor'
    //   }
    // },
  ]
}