import axios from '@/http/axios'
import {createCancelTokenHandler} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getBanks: createCancelTokenHandler('getBanks'),
  getBanksTrash: createCancelTokenHandler('getBanksTrash'),
  getBankTransactions: createCancelTokenHandler('getBankTransactions'),
  getBankActivitiesLog: createCancelTokenHandler('getBankActivitiesLog')
}

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

export function getBanks (page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/banks${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getBanks'].handleRequestCancellation().token
  })
}

export function getBanksTrash (page = 1, filters = [], sorts = []) {

  let queries = makeQuery(page, filters, sorts)

  queries = queries.length > 0 ? `&${  queries.join('&')}` : ''

  return axios({
    url: `v1/admin/treasury/banks?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getBanksTrash'].handleRequestCancellation().token
  })
}

export function getBank (id) {
  return axios({
    url: `v1/admin/treasury/banks/${id}`,
    method: 'get'
  })
}

export function getBankTransactions (id, page = 1, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/banks/${id}/transactions${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getBankTransactions'].handleRequestCancellation().token
  })
}

export function printBankTransactions (id, filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/banks/${id}/transactions?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getBankActivitiesLog (id, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/admin/treasury/banks/${id}/activity-logs${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getBankActivitiesLog'].handleRequestCancellation().token
  })
}

export function insertBank (payment) {
  return axios({
    url: 'v1/admin/treasury/banks',
    data: payment,
    method: 'post'
  })
}

export function editBank (payment) {
  return axios.patch(`v1/admin/treasury/banks/${payment.id}`, payment)
}

export function restoreBank (id) {
  return axios({
    url: `v1/admin/treasury/banks/${id}/restore`,
    method: 'put'
  })
}

export function deleteBank (id, force = false) {
  return axios({
    url: `v1/admin/treasury/banks/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}
