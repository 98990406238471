// tickets

export default {
  title: 'Tickets',

  support: {
    title: 'Support ticket',

    statusTypes: {
      all: 'All',
      created: 'Created',
      customerAnswer: 'Customer response',
      operatorAnswer: 'Operator response',
      pending: 'Awaiting review',
      completed: 'Closed',
      canceled: 'Canceled'
    },

    sendStatusTypes: {
      send: 'Sent',
      notSend: 'Not sent'
    },

    seenStatusTypes: {
      seen: 'Seen',
      notSeen: 'Unseen'
    },

    labels: {
      all: 'All',
      selectType: 'Select the subject',
      service: 'Service',
      subject: 'Ticket subject',
      message: 'Message',
      dynamicAttach: 'Attach {number}',
      insertAttach: 'New attachment',
      unknown: 'Unknown',
      operator: 'Operator'
    },

    placeholder: {
      subject: 'Enter the subject of your ticket',
      message: 'Enter the text of your ticket'
    },

    table: {
      header: {
        row: 'Row',
        department: 'Department',
        service: 'System name',
        subject: 'Subject',
        date: 'Created date',
        lastUpdate: 'Latest update',
        operatorName: 'Operator',
        creator: 'Creator',
        status: 'Status',
        sendStatus: 'Send status',
        seen: 'Seen'
      }
    },

    notifications: {
      attachUpload: 'The file was uploaded',
      requestSend: 'Your request has been sent, please wait!',

      insert: {
        success: 'The ticket was created successfully',
        error: 'Insert ticket has error'
      },

      sendMessage: {
        success: 'The message was sent successfully',
        error: 'Send message has error'
      },

      changeOperator: {
        success: 'Ticket operator edited successfully',
        error: 'Edit ticket operator has error'
      },

      parseError: {
        service: 'The selected service is invalid!',
        subject: 'The selected Subject is not valid!',
        message: 'The text entered is invalid!',
        attachments: 'The selected attachment is invalid!',
        operator: 'The selected operator is not valid!'
      }
    },

    confirmations: {
      send: {
        title: 'Send message confirmation',
        body: 'Are you sure you want to send your message?'
      }
    },

    validators: {
      subject: 'The subject of the ticket was not entered correctly!',
      message: 'The message text was not entered correctly!',
      service: 'The service is not selected correctly!'
    },

    list: {
      title: 'Support tickets'
    },

    insert: {
      title: 'Insert new support ticket'
    },

    answer: {
      title: 'Add reply to support ticket'
    },

    detail: {
      title: 'Support ticket detail',

      labels: {
        ticketId: 'Ticket ',
        status: 'Ticket status: ',
        createdAt: 'Created date: ',
        lastUpdate: 'Last update: ',
        user: 'User',
        operator: 'Operator'
      }
    }
  },

  organization: {
    title: 'Organization ticket',

    statusTypes: {
      all: 'All',
      created: 'Created date',
      creatorAnswer: 'Creator response',
      receiverAnswer: 'Operator response',
      pending: 'Awaiting review',
      completed: 'Closed',
      canceled: 'Canceled'
    },

    sendStatusTypes: {
      send: 'Sent',
      notSend: 'Not sent'
    },

    seenStatusTypes: {
      seen: 'Seen',
      notSeen: 'Unseen'
    },

    labels: {
      all: 'All',
      selectType: 'Select the subject',
      user: 'Receiver',
      subject: 'Ticket subject',
      message: 'Message',
      dynamicAttach: 'Attach {number}',
      insertAttach: 'New attachment',
      unknown: 'Unknown',
      operator: 'Operator'
    },

    placeholder: {
      subject: 'Enter the subject of your ticket',
      message: 'Enter the text of your ticket'
    },

    table: {
      header: {
        row: 'row',
        creator: 'Creator',
        user: 'Operator',
        subject: 'Subject',
        date: 'Created date',
        lastUpdate: 'Last update',
        status: 'Status',
      }
    },

    notifications: {
      attachUpload: 'The file was uploaded',
      requestSend: 'Your request has been sent, please wait!',

      insert: {
        success: 'The ticket was created successfully',
        error: 'Insert ticket has error'
      },

      sendMessage: {
        success: 'The message was sent successfully',
        error: 'Send message has error'
      },

      changeOperator: {
        success: 'Ticket operator edited successfully',
        error: 'Edit Ticket operator has error'
      },

      parseError: {
        user: 'The selected receiver is not valid!',
        service: 'The selected service is invalid!',
        subject: 'The selected Subject is not valid!',
        message: 'The text entered is invalid!',
        attachments: 'The selected attachment is invalid!',
        operator: 'The selected operator is not valid!'
      }
    },

    confirmations: {
      send: {
        title: 'Send message confirmation',
        body: 'Are you sure you want to send your message?'
      }
    },

    validators: {
      user: 'The receiver of the ticket was not entered correctly!',
      subject: 'The subject of the ticket was not entered correctly!',
      message: 'The message text was not entered correctly!',
      service: 'The service is not selected correctly!'
    },

    list: {
      title: 'Organization tickets'
    },

    insert: {
      title: 'Insert new organization ticket'
    },

    answer: {
      title: 'Add reply to organization ticket'
    },

    detail: {
      title: 'Organization ticket detail',

      labels: {
        ticketId: 'Ticket id',
        status: 'Ticket Status: ',
        createdAt: 'Created date: ',
        lastUpdate: 'Last update: ',
        user: 'User',
        operator: 'Operator'
      }
    }
  },
}