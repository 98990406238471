export default {
  list: {
    table: {
      header: {
        rowNumber: 'ردیف',
        user: 'کاربر',
        description: 'توضیحات',
        date: 'تاریخ'
      }
    },

    dialogs: {
      title: 'توضیحات',

      noChange: 'فیلدی تغییری داده نشده است'
    }
  },

  login: 'کاربر وارد سامانه شد',
  reset_app: 'سامانه بازنشانی شد',

  user: {
    store: 'اطلاعات کاربر ایجاد شد',
    update: 'اطلاعات کاربر به روزرسانی شد',
    soft_destroy: 'اطلاعات کاربر حذف موقت شد',
    restore: 'اطلاعات کاربر بازگردانی شد',
    destroy: 'اطلاعات کاربر حذف شد',
    set_role: 'به کاربر نقش تخصیص داده شد',
    set_max_debt: 'سقف بدهکاری شخص به روز رسانی شد'
  },

  user_address: {
    store: 'آدرس جدید به کاربر اضافه شد',
    update: 'آدرس کاربر به روز شد',
    destroy: 'آدرس کاربر حذف شد'
  },

  invoice: {
    store: 'اطلاعات فاکتور ایجاد شد',
    update: 'اطلاعات فاکتور بروزرسانی شد',
    force_update: 'اطلاعات فاکتور به صورت اجباری بروزرسانی شد',
    soft_destroy: 'اطلاعات فاکتور به صورت موقت حذف شد',
    restore: 'اطلاعات فاکتور بازگردانی شد',
    destroy: 'اطلاعات فاکتور حذف شد',
    complete: 'فاکتور پرداخت شد',
    refresh_status: 'وضعیت فاکتور تغییر داده شد'
  },

  sale_addon: {
    store: 'اطلاعات افزودنی رزرو ایجاد شد',
    update: 'اطلاعات افزودنی رزرو به روزرسانی شد',
    soft_destroy: 'اطلاعات افزودنی رزرو حذف موقت شد',
    restore: 'اطلاعات افزودنی رزرو بازگردانی شد',
    destroy: 'اطلاعات افزودنی رزرو حذف شد'
  },

  term: {
    store: 'اطلاعات سانس ایجاد شد',
    update: 'اطلاعات سانس به روزرسانی شد',
    soft_destroy: 'اطلاعات سانس حذف موقت شد',
    restore: 'اطلاعات سانس بازگردانی شد',
    destroy: 'اطلاعات سانس حذف شد'
  },

  court: {
    store: 'اطلاعات زمین ایجاد شد',
    update: 'اطلاعات زمین به روزرسانی شد',
    soft_destroy: 'اطلاعات زمین حذف موقت شد',
    restore: 'اطلاعات زمین بازگردانی شد',
    destroy: 'اطلاعات زمین حذف شد'
  },

  closed_invoice: {
    store: 'اطلاعات اعلامیه تعطیلات ایجاد شد',
    update: 'اطلاعات اعلامیه تعطیلات به روزرسانی شد',
    soft_destroy: 'اطلاعات اعلامیه تعطیلات حذف موقت شد',
    restore: 'اطلاعات اعلامیه تعطیلات بازگردانی شد',
    destroy: 'اطلاعات اعلامیه تعطیلات حذف شد'
  },

  coach: {
    store: 'اطلاعات مربی ایجاد شد',
    update: 'اطلاعات مربی به روزرسانی شد',
    soft_destroy: 'اطلاعات مربی حذف موقت شد',
    restore: 'اطلاعات مربی بازگردانی شد',
    destroy: 'اطلاعات مربی حذف شد'
  },

  character: {
    store: 'اطلاعات گروه مشتری ایجاد شد',
    update: 'اطلاعات گروه مشتری به روزرسانی شد',
    soft_destroy: 'اطلاعات گروه مشتری حذف موقت شد',
    restore: 'اطلاعات گروه مشتری بازگردانی شد',
    destroy: 'اطلاعات گروه مشتری حذف شد'
  },

  role: {
    store: 'گروه دسترسی ایجاد شد',
    update: 'گروه دسترسی بروزرسانی شد',
    destroy: 'گروه دسترسی حذف شد'
  },

  receive: {
    store: 'رسید دریافت ایجاد شد',
    update: 'رسید دریافت بروزرسانی شد',
    destroy: 'رسید دریافت حذف شد'
  },

  payment: {
    store: 'رسید پرداخت ایجاد شد',
    update: 'رسید پرداخت بروزرسانی شد',
    destroy: 'رسید پرداخت حذف شد'
  },

  internal_fund: {
    store: 'رسید انتقالی خزانه ایجاد شد',
    update: 'رسید انتقالی خزانه بروزرسانی شد',
    destroy: 'رسید انتقالی خزانه حذف شد'
  },

  cost: {
    store: 'رسید هزینه ایجاد شد',
    update: 'رسید هزینه بروزرسانی شد',
    destroy: 'رسید هزینه حذف شد'
  },

  bank: {
    store: 'اطلاعات بانک ایجاد شد',
    update: 'اطلاعات بانک بروزرسانی شد',
    soft_destroy: 'اطلاعات بانک به صورت موقت حذف شد',
    restore: 'اطلاعات بانک بازگردانی شد',
    destroy: 'اطلاعات بانک حذف شد'
  },

  cash: {
    store: 'اطلاعات صندوق ایجاد شد',
    update: 'اطلاعات صندوق بروزرسانی شد',
    soft_destroy: 'اطلاعات صندوق به صورت موقت حذف شد',
    restore: 'اطلاعات صندوق بازگردانی شد',
    destroy: 'اطلاعات صندوق حذف شد'
  },

  payment_gateway: {
    store: 'اطلاعات درگاه پرداخت ایجاد شد',
    update: 'اطلاعات درگاه پرداخت بروزرسانی شد',
    soft_destroy: 'اطلاعات درگاه پرداخت به صورت موقت حذف شد',
    restore: 'اطلاعات درگاه پرداخت بازگردانی شد',
    destroy: 'اطلاعات درگاه پرداخت حذف شد'
  },

  variablesLabel: {
    invoice: {
      type: {
        1: 'فروش',
        2: 'خرید',
        3: 'مرجوع فروش',
        4: 'مرجوع خرید'
      },
      action_type: {
        1: 'حضوری',
        2: 'تلفنی',
        3: 'اینترنتی'
      },
      cancel_type: {
        1: 'لغو شده',
        2: 'لغو شده سیستمی'
      },
      status: {
        1: 'رزرو فعال',
        2: 'لغو شده',
        3: 'رزرو استفاده شده',
        4: 'لغو شده سیستمی'
      }
    },
    receive: {
      status: {
        0: 'در انتظار',
        1: 'تایید شده'
      }
    },
    cost: {
      payment_type: {
        ctc: 'کارت به کارت',
        iban: 'شبا'
      }
    },
    payment: {
      payment_type: {
        ctc: 'کارت به کارت',
        iban: 'شبا'
      }
    },
    internal_fund: {
      payment_type: {
        ctc: 'کارت به کارت',
        iban: 'شبا'
      },
      type: {
        1: 'انتقالی خزانه',
        2: 'اعلام وصول درگاه'
      }
    },
    payment_gateway: {
      type: {
        1: 'درگاه پرداخت اینترنتی',
        2: 'کارتخوان'
      },
      enabled: {
        1: 'فعال',
        0: 'غیرفعال'
      }
    },
    coach: {
      payment_type: {
        0: 'روزانه',
        1: 'هفتگی',
        2: 'ماهانه'
      }
    },
    court: {
      type: {
        1: 'هارد',
        2: 'خاک',
        3: 'چمن'
      }
    }
  },

  changeFields: {
    store: {
      user: {
        character: 'گروه مشتری «{new}» ثبت شد.',
        role: 'نقش کاربر «{new}» ثبت شد.',
        name: 'نام «{new}» ثبت شد.',
        family: 'نام خانوادگی «{new}» ثبت شد.',
        gender: 'جنسیت «{new}» ثبت شد.',
        avatar: 'عکس پروفایل کاربر ثبت شد',
        company: 'شرکت «{new}» ثبت شد.',
        phone_number: 'شماره تلفن «{new}» ثبت شد.',
        email: 'ایمیل «{new}» ثبت شد.',
        max_debt: 'سقف بدهکاری «{new}» ثبت شد.',
      },

      user_address: {
        addresses: {
          address: 'آدرس «{new}» ثبت شد.',
          city: 'شهر «{new}» ثبت شد.',
          province: 'استان «{new}» ثبت شد.',
          post_code: 'کد پستی «{new}» ثبت شد.',
          receiver_call_number: 'تلفن گیرنده «{new}» ثبت شد.',
          receiver_family: 'نام خانوادگی گیرنده «{new}» ثبت شد.',
          receiver_name: 'نام گیرنده «{new}» ثبت شد.',
          title: 'عنوان «{new}» ثبت شد.'
        }
      },

      bank: {
        name: 'نام «{new}» ثبت شد.',
        card_number: 'شماره کارت «{new}» ثبت شد.',
        account_number: 'شماره حساب «{new}» ثبت شد.',
        shaba_number: 'شماره شبا «{new}» ثبت شد.'
      },

      cash: {
        name: 'نام «{new}» ثبت شد.'
      },

      payment_gateway: {
        type: 'نوع درگاه پرداخت «{new}» ثبت شد.',
        title: 'عنوان درگاه «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        bank: 'بانک تسویه «{new}» ثبت شد.',
        provider: 'ارائه دهنده «{new}» ثبت شد.',
        enabled: 'وضعیت درگاه پرداخت «{new}» ثبت شد.',
        end_time: 'ساعت پایان روز کاری «{new}» ثبت شد.',
        merchant_code: 'کد پذیرنده «{new}» ثبت شد.',
        terminal_code: 'کد پایانه «{new}» ثبت شد.'
      },

      cost: {
        document: 'شماره سند «{new}» ثبت شد.',
        payer: 'طرف حساب «{new}» ثبت شد.',
        account: 'سرفصل هزینه «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        price: 'قیمت «{new}» ثبت شد.',
        wage: 'کارمزد «{new}» ثبت شد.',
        final_price: 'مبلغ پایانی «{new}» ثبت شد.',
        payment_type: 'نوع پرداخت «{new}» ثبت شد.',
        date: 'تاریخ «{new}» ثبت شد.'
      },

      internal_fund: {
        document: 'شماره سند «{new}» ثبت شد.',
        payer: 'پرداخت کننده «{new}» ثبت شد.',
        payee: 'دریافت کننده «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        type: 'نوع «{new}» ثبت شد.',
        payment_type: 'نوع پرداخت «{new}» ثبت شد.',
        price: 'قیمت «{new}» ثبت شد.',
        wage: 'کارمزد «{new}» ثبت شد.',
        final_price: 'مبلغ نهایی «{new}» ثبت شد.',
        reference: 'شماره مرجع «{new}» ثبت شد.',
        date: 'تاریخ «{new}» ثبت شد.'
      },

      payment: {
        document: 'شماره سند «{new}» ثبت شد.',
        payer: 'پرداخت کننده «{new}» ثبت شد.',
        payee: 'دریافت کننده «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        payment_type: 'نوع پرداخت «{new}» ثبت شد.',
        price: 'قیمت «{new}» ثبت شد.',
        wage: 'کارمزد بانکی «{new}» ثبت شد.',
        final_price: 'قیمت پایانی «{new}» ثبت شد.',
        type: 'نوع «{new}» ثبت شد.',
        date: 'تاریخ «{new}» ثبت شد.'
      },

      receive: {
        document: 'شماره سند «{new}» ثبت شد.',
        payer: 'پرداخت کننده «{new}» ثبت شد.',
        payee: 'دریافت کننده «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        price: 'قیمت «{new}» ثبت شد.',
        status: 'وضعیت «{new}» ثبت شد.',
        confirmation: 'شماره تاییدیه «{new}» ثبت شد.',
        transaction: 'شماره تراکنش «{new}» ثبت شد.',
        reference: 'شماره مرجع «{new}» ثبت شد.',
        date: 'تاریخ «{new}» ثبت شد.'
      },

      role: {
        name: 'نام «{new}» ثبت شد.',
        parent: 'والد «{new}» ثبت شد.',
        allowed_active_sessions: 'تعداد نشست های فعال «{new}» ثبت شد.',
        permissions: {
          id: 'شناسه دسترسی «{new}» ثبت شد.',
          name: 'نام دسترسی «{new}» ثبت شد.',
          slug: 'نامک دسترسی «{new}» ثبت شد.'
        }
      },

      sale_addon: {
        name: 'نام «{new}» ثبت شد.',
        max_select: 'حداکثر تعداد قابل انتخاب «{new}» ثبت شد.',
      },

      coach: {
        user: 'نام مربی «{new}» ثبت شد.',
        payment_type: 'دوره تسویه با مربی «{new}» ثبت شد.',
        contract_price: 'مبلغ خرید «{new}» ثبت شد.',
        reserve_price: 'مبلغ فروش «{new}» ثبت شد.',
      },

      character: {
        name: 'نام «{new}» ثبت شد.',
        reservable_date: 'حداکثر روز قابل رزرو «{new}» ثبت شد.',
        creator: 'ایجاد کننده «{new}» ثبت شد.'
      },

      term: {
        name: 'نام «{new}» ثبت شد.',
        start_time: 'شروع سانس «{new}» ثبت شد.',
        end_time: 'پایان سانس «{new}» ثبت شد.'
      },

      court: {
        name: 'نام «{new}» ثبت شد.',
        avatar: 'عکس زمین ثبت شد.',
        type: 'نوع «{new}» ثبت شد.'
      },

      closed_invoice: {
        invoice_date: 'تاریخ فاکتور «{new}» ثبت شد.',
        note: 'توضیحات «{new}» ثبت شد.',
        lines: {
          reserved_date: 'تاریخ رزرو «{new}» ثبت شد.',
          reserved_time: 'ساعت رزرو «{new}» ثبت شد.'
        }
      },

      invoice: {
        user: 'طرف حساب «{new}» ثبت شد.',
        invoice_number: 'شماره فاکتور «{new}» ثبت شد.',
        type: 'نوع «{new}» ثبت شد.',
        action_type: 'نوع رزرو «{new}» ثبت شد.',
        status: 'وضعیت «{new}» ثبت شد.',
        invoice_date: 'تاریخ فاکتور «{new}» ثبت شد.',
        reserved_date: 'تاریخ رزرو «{new}» ثبت شد.',
        details: 'جزئیات «{new}» ثبت شد.',
        note: 'یادداشت «{new}» ثبت شد.',
        reserved_time: 'زمان رزرو «{new}» ثبت شد.',
        discount: 'تخفیف «{new}» ثبت شد.',
        price: 'مبلغ فاکتور «{new}» ثبت شد.',
        cancel_price: 'مبلغ لغو رزرو «{new}» ثبت شد.',
        coach_price: 'مبلغ رزرو مربی «{new}» ثبت شد.',
        addons_price: 'مبلغ افزودنی «{new}» ثبت شد.',
        cancel_type: 'نوع لغو رزرو «{new}» ثبت شد.',
        final_price: 'قیمت نهایی «{new}» ثبت شد.',
        address: {
          province: 'استان «{new}» ثبت شد.',
          city: 'شهر «{new}» ثبت شد.',
          address: 'آدرس «{new}» ثبت شد.',
          post_code: 'کد پستی «{new}» ثبت شد.',
          receiver_name: 'نام گیرنده «{new}» ثبت شد.',
          receiver_family: 'نام خانوادگی گیرنده «{new}» ثبت شد.',
          receiver_call_number: 'تلفن گیرنده «{new}» ثبت شد.'
        },
        lines: {
          product_variant: 'متغیر محصول «{new}» ثبت شد.',
          storeroom: 'انبار «{new}» ثبت شد.',
          unit_price: 'قیمت واحد «{new}» ثبت شد.',
          quantity: 'تعداد محصول «{new}» ثبت شد.',
          discount: 'تخفیف «{new}» ثبت شد.',
          tax: 'مالیات «{new}» ثبت شد.'
        }
      }
    },

    update: {
      user: {
        character: 'گروه مشتری از «{old}» به «{new}» بروزرسانی شد.',
        role: 'نقش کاربر از «{old}» به «{new}» بروزرسانی شد.',
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        family: 'نام خانوادگی از «{old}» به «{new}» بروزرسانی شد.',
        gender: 'جنسیت از «{old}» به «{new}» بروزرسانی شد.',
        avatar: 'عکس پروفایل کاربر بروزرسانی شد',
        company: 'شرکت از «{old}» به «{new}» بروزرسانی شد.',
        phone_number: 'شماره تلفن از «{old}» به «{new}» بروزرسانی شد.',
        email: 'ایمیل از «{old}» به «{new}» بروزرسانی شد.',
        max_debt: 'سقف بدهکاری از «{old}» به «{new}» بروزرسانی شد.',
      },

      user_address: {
        addresses: {
          address: 'آدرس از «{old}» به «{new}» بروزرسانی شد.',
          city: 'شهر از «{old}» به «{new}» بروزرسانی شد.',
          province: 'استان از «{old}» به «{new}» بروزرسانی شد.',
          post_code: 'کد پستی از «{old}» به «{new}» بروزرسانی شد.',
          receiver_call_number: 'تلفن گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          receiver_family: 'نام خانوادگی گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          receiver_name: 'نام گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          title: 'عنوان از «{old}» به «{new}» بروزرسانی شد.'
        }
      },

      bank: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        card_number: 'شماره کارت از «{old}» به «{new}» بروزرسانی شد.',
        account_number: 'شماره حساب از «{old}» به «{new}» بروزرسانی شد.',
        shaba_number: 'شماره شبا از «{old}» به «{new}» بروزرسانی شد.'
      },

      cash: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.'
      },

      payment_gateway: {
        type: 'نوع درگاه پرداخت بروزرسانی شد',
        title: 'عنوان درگاه از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        bank: 'بانک تسویه از «{old}» به «{new}» بروزرسانی شد.',
        provider: 'ارائه دهنده از «{old}» به «{new}» بروزرسانی شد.',
        enabled: 'وضعیت درگاه پرداخت بروزرسانی شد.',
        end_time: 'ساعت پایان روز کاری از «{old}» به «{new}» بروزرسانی شد.',
        merchant_code: 'کد پذیرنده از «{old}» به «{new}» بروزرسانی شد.',
        terminal_code: 'کد پایانه از «{old}» به «{new}» بروزرسانی شد.'
      },

      cost: {
        document: 'شماره سند از «{old}» به «{new}» بروزرسانی شد.',
        payer: 'طرف حساب از «{old}» به «{new}» بروزرسانی شد.',
        account: 'سرفصل هزینه از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
        wage: 'کارمزد از «{old}» به «{new}» بروزرسانی شد.',
        final_price: 'مبلغ پایانی از «{old}» به «{new}» بروزرسانی شد.',
        payment_type: 'نوع پرداخت از «{old}» به «{new}» بروزرسانی شد.',
        date: 'تاریخ از «{old}» به «{new}» بروزرسانی شد.'
      },

      internal_fund: {
        document: 'شماره سند از «{old}» به «{new}» بروزرسانی شد.',
        payer: 'پرداخت کننده از «{old}» به «{new}» بروزرسانی شد.',
        payee: 'دریافت کننده از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        type: 'نوع از «{old}» به «{new}» بروزرسانی شد.',
        payment_type: 'نوع پرداخت از «{old}» به «{new}» بروزرسانی شد.',
        price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
        wage: 'کارمزد از «{old}» به «{new}» بروزرسانی شد.',
        final_price: 'مبلغ نهایی از «{old}» به «{new}» بروزرسانی شد.',
        reference: 'شماره مرجع از «{old}» به «{new}» بروزرسانی شد.',
        date: 'تاریخ از «{old}» به «{new}» بروزرسانی شد.'
      },

      payment: {
        document: 'شماره سند از «{old}» به «{new}» بروزرسانی شد.',
        payer: 'پرداخت کننده از «{old}» به «{new}» بروزرسانی شد.',
        payee: 'دریافت کننده از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        payment_type: 'نوع پرداخت از «{old}» به «{new}» بروزرسانی شد.',
        price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
        wage: 'کارمزد بانکی از «{old}» به «{new}» بروزرسانی شد.',
        final_price: 'قیمت پایانی از «{old}» به «{new}» بروزرسانی شد.',
        type: 'نوع از «{old}» به «{new}» بروزرسانی شد.',
        date: 'تاریخ از «{old}» به «{new}» بروزرسانی شد.'
      },

      receive: {
        document: 'شماره سند از «{old}» به «{new}» بروزرسانی شد.',
        payer: 'پرداخت کننده از «{old}» به «{new}» بروزرسانی شد.',
        payee: 'دریافت کننده از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
        status: 'وضعیت از «{old}» به «{new}» بروزرسانی شد.',
        confirmation: 'شماره تاییدیه از «{old}» به «{new}» بروزرسانی شد.',
        transaction: 'شماره تراکنش از «{old}» به «{new}» بروزرسانی شد.',
        reference: 'شماره مرجع از «{old}» به «{new}» بروزرسانی شد.',
        date: 'تاریخ از «{old}» به «{new}» بروزرسانی شد.'
      },

      role: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        parent: 'والد از «{old}» به «{new}» بروزرسانی شد.',
        allowed_active_sessions: 'تعداد نشست های فعال از «{old}» به «{new}» بروزرسانی شد.',
        permissions: {
          id: 'شناسه دسترسی از «{old}» به «{new}» بروزرسانی شد.',
          name: 'نام دسترسی از «{old}» به «{new}» بروزرسانی شد.',
          slug: 'نامک دسترسی از «{old}» به «{new}» بروزرسانی شد.'
        }
      },

      sale_addon: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        max_select: 'حداکثر تعداد قابل انتخاب از «{old}» به «{new}» بروزرسانی شد.'
      },

      coach: {
        user: 'نام مربی از «{old}» به «{new}» بروزرسانی شد.',
        payment_type: 'دوره تسویه با مربی از «{old}» به «{new}» بروزرسانی شد.',
        contract_price: 'مبلغ خرید از «{old}» به «{new}» بروزرسانی شد.',
        reserve_price: 'مبلغ فروش از «{old}» به «{new}» بروزرسانی شد.'
      },

      character: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        reservable_date: 'حداکثر روز قابل رزرو از «{old}» به «{new}» بروزرسانی شد.',
        creator: 'ایجاد کننده از «{old}» به «{new}» بروزرسانی شد.'
      },

      term: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        start_time: 'شروع سانس از «{old}» به «{new}» بروزرسانی شد.',
        end_time: 'پایان سانس از «{old}» به «{new}» بروزرسانی شد.'
      },

      court: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        avatar: 'عکس زمین بروزرسانی شد.',
        type: 'نوع از «{old}» به «{new}» بروزرسانی شد.'
      },

      closed_invoice: {
        invoice_date: 'تاریخ فاکتور از «{old}» به «{new}» بروزرسانی شد.',
        note: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        reserved_date: 'تاریخ رزرو از «{old}» به «{new}» بروزرسانی شد.',
        reserved_time: 'ساعت رزرو از «{old}» به «{new}» بروزرسانی شد.'
      },

      invoice: {
        user: 'طرف حساب از «{old}» به «{new}» بروزرسانی شد.',
        invoice_number: 'شماره فاکتور از «{old}» به «{new}» بروزرسانی شد.',
        type: 'نوع از «{old}» به «{new}» بروزرسانی شد.',
        action_type: 'نوع رزرو از «{old}» به «{new}» بروزرسانی شد.',
        status: 'وضعیت از «{old}» به «{new}» بروزرسانی شد.',
        invoice_date: 'تاریخ فاکتور از «{old}» به «{new}» بروزرسانی شد.',
        reserved_date: 'تاریخ رزرو از «{old}» به «{new}» بروزرسانی شد.',
        details: 'جزئیات از «{old}» به «{new}» بروزرسانی شد.',
        note: 'یادداشت از «{old}» به «{new}» بروزرسانی شد.',
        reserved_time: 'زمان رزرو از «{old}» به «{new}» بروزرسانی شد.',
        discount: 'تخفیف از «{old}» به «{new}» بروزرسانی شد.',
        price: 'مبلغ فاکتور از «{old}» به «{new}» بروزرسانی شد.',
        cancel_price: 'مبلغ لغو رزرو از «{old}» به «{new}» بروزرسانی شد.',
        coach_price: 'مبلغ رزرو مربی از «{old}» به «{new}» بروزرسانی شد.',
        addons_price: 'مبلغ افزودنی از «{old}» به «{new}» بروزرسانی شد.',
        cancel_type: 'نوع لغو رزرو از «{old}» به «{new}» بروزرسانی شد.',
        final_price: 'قیمت نهایی از «{old}» به «{new}» بروزرسانی شد.',
        address: {
          province: 'استان از «{old}» به «{new}» بروزرسانی شد.',
          city: 'شهر از «{old}» به «{new}» بروزرسانی شد.',
          address: 'آدرس از «{old}» به «{new}» بروزرسانی شد.',
          post_code: 'کد پستی از «{old}» به «{new}» بروزرسانی شد.',
          receiver_name: 'نام گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          receiver_family: 'نام خانوادگی گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          receiver_call_number: 'تلفن گیرنده از «{old}» به «{new}» بروزرسانی شد.'
        },
        lines: {
          product_variant: 'متغیر محصول از «{old}» به «{new}» بروزرسانی شد.',
          storeroom: 'انبار از «{old}» به «{new}» بروزرسانی شد.',
          unit_price: 'قیمت واحد از «{old}» به «{new}» بروزرسانی شد.',
          quantity: 'تعداد محصول از «{old}» به «{new}» بروزرسانی شد.',
          discount: 'تخفیف از «{old}» به «{new}» بروزرسانی شد.',
          tax: 'مالیات از «{old}» به «{new}» بروزرسانی شد.'
        }
      }
    }
  }
}
