export default {
  path: 'invoices',
  component: () => import('../../../views/admin/sales/invoices/index.vue'),
  children: [
    {
      path: '/',
      name: 'saleInvoices',
      component: () => import('../../../views/admin/sales/invoices/list/saleInvoicesList.vue'),
      meta: {
        permission: 'invoice.show',
        pageTitle: 'رزرو تایم',
        pageTitleI18n: 'sales.invoices.list.title'
        // rule: 'editor'
      }
    },
    {
      path: 'trash',
      name: 'saleInvoicesTrash',
      component: () => import('../../../views/admin/sales/invoices/trash/saleInvoiceTrash.vue'),
      meta: {
        permission: 'invoice.delete',
        pageTitle: 'سطل زباله لیست رزرو',
        pageTitleI18n: ''
        // rule: 'editor'
      }
    },
    {
      path: 'trash/:id',
      name: 'saleInvoiceTrash',
      component: () => import('../../../views/admin/sales/invoices/invoice/saleInvoice.vue'),
      meta: {
        permission: 'invoice.delete',
        pageTitle: 'فاکتور رزرو حذف شده',
        pageTitleI18n: ''
        // rule: 'editor'
      }
    },
    {
      path: 'insert',
      name: 'insertSaleInvoice',
      component: () => import('../../../views/admin/sales/invoices/insert/insertTimeSaleInvoice.vue'),
      meta: {
        permission: 'invoice.create',
        pageTitle: 'افزودن فاکتور فروش',
        pageTitleI18n: 'sales.invoices.insert.title'
        // rule: 'editor'
      }
    },
    {
      path: 'insert/past',
      name: 'insertPastSaleInvoice',
      component: () => import('../../../views/admin/sales/invoices/insert/insertTimeSaleInvoice.vue'),
      meta: {
        permission: 'invoice.forget',
        pageTitle: 'ثبت فاکتور رزرو برای گذشته',
        pageTitleI18n: 'sales.invoices.insert.past.title'
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      name: 'saleInvoice',
      component: () => import('../../../views/admin/sales/invoices/invoice/saleInvoice.vue'),
      meta: {
        permission: 'invoice.show',
        pageTitle: 'فاکتور رزرو',
        pageTitleI18n: 'sales.invoices.invoice.title'
        // rule: 'editor'
      }
    },
    {
      path: ':id/edit',
      name: 'editSaleInvoice',
      component: () => import('../../../views/admin/sales/invoices/edit/editSaleInvoice.vue'),
      meta: {
        permission: 'invoice.update',
        pageTitle: 'ویرایش فاکتور فروش',
        pageTitleI18n: 'sales.invoices.edit.title'
        // rule: 'editor'
      }
    },
    {
      path: ':id/activities-log',
      name: 'saleInvoiceActivitiesLog',
      component: () => import(/* webpackChunkName: "saleInvoiceActivitiesLog" */ '../../../views/admin/sales/invoices/activitiesLog/saleInvoiceActivitiesLog.vue'),
      meta: {
        permission: 'activity_logs.show',
        pageTitle: 'لاگ های فاکتور فروش',
        pageTitleI18n: 'sales.invoices.invoice.logs.title'
        // rule: 'editor'
      }
    },
  ]
}