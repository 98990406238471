// courts

export default {
  title: 'Courts',

  labels: {
    image: 'Picture of the location of the court in the club',
    name: 'Name',
    type: 'Court type',
    delete: 'Delete Court',
  },

  types: {
    all: 'All',
    hard: 'Hard court',
    ground: 'Clay court',
    grass: 'Grass court'
  },

  roofTypes: {
    always: 'دائم',
    season: 'فصلی',
    without: 'سرباز'
  },

  notifications: {
    insert: {
      success: 'Court information was successfully added',
      error: 'Adding court information has error'
    },

    edit: {
      success: 'Court information successfully updated',
      error: 'Editing court information has error'
    },

    delete: {
      success: 'The court information was deleted',
      error: 'Deleting court has error',
      isUsedError: 'The court can not be removed due to the history of documents!'
    },

    restore: {
      success: 'The court was successfully restored',
      error: 'The restoration of the court has error'
    },

    parseError: {
      name: 'The selected name is invalid!',
      type: 'Court type is not valid!',
      image: 'The selected image is invalid!'
    }
  },

  validators: {
    name: 'The entered name is incorrect',
    type: 'The selected court type is incorrect'
  },

  confirmations: {
    delete: {
      title: 'Delete court confirmation',
      body: 'Are you sure you want to delete the {name} temporarily?'
    },

    forceDelete: {
      title: 'Delete court confirmation',
      body: 'Are you sure you want to delete the {name} permanently?'
    }
  },

  list: {
    title: 'Courts list',

    table: {
      header: {
        row: 'row',
        image: 'Earth position',
        name: 'Court name',
        type: 'Court type',
        createdAt: 'Created date',
        delete: 'Delete',
        restore: 'Restore'
      }
    }
  },

  insert: {
    title: 'Add new court'
  },

  edit: {
    title: 'Edit court',
    dynamicTitle: 'Edit {name} information'
  },

  profile: {
    title: 'Court profile',
    dynamicTitle: '{name} profile',

    actions: {
      edit: 'Edit',
      price: 'Court pricing',
      logs: 'Activity logs'
    },

    asiderAvatar: {
      type: 'Court type: '
    }
  },

  trash: {
    title: 'Removed courts'
  },

  price: {
    table: {
      header: {
        termName: 'Time category name',
        saturday: 'Saturday',
        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday'
      }
    },

    notifications: {
      edit: {
        success: 'Price list editing completed successfully',
        error: 'There was an error editing the price list'
      }
    },

    list: {
      title: 'Court price list',
      dynamicTitle: '{name} pricing'
    },

    edit: {
      title: 'Edit court price list',
      dynamicTitle: 'Edit {name} price list'
    }
  },

  logs: {
    title: 'Court Logs'
  }
}