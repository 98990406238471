// setting

export default {
  title: 'Setting',

  asiderAvatar: {
    support: 'Support: ',
    whatsApp: 'WhatsApp: '
  },

  actions: {
    edit: 'Edit',
    address: 'Address',
    tel: 'Call',
    treasury: 'Treasury',
    language: 'Language',
    message: 'SMS panel',
    faq: 'FAQ',
    rules: 'Terms and Conditions',
    reset: 'Reset system'
  },

  notifications: {
    editRules: {
      success: 'Club terms and conditions have been successfully updated'
    },

    editAddress: {
      success: 'Club address information updated successfully'
    },

    editTel: {
      success: 'Club call information updated successfully'
    },

    editTreasury: {
      success: 'Treasury information updated successfully',
      negativeBalance: 'Due to the negative balance of a number of banks or Cash registers, saving the desired settings was accompanied by an error'
    },

    editLocale: {
      success: 'اطلاعات زبان با موفقیت به روز شد.',
      error: 'به روزرسانی اطلاعات زبان با خطا همراه شد.'
    },

    editMessage: {
      success: 'Club SMS panel information updated successfully'
    },

    editFaq: {
      success: 'FAQ was updated successfully'
    },

    editClub: {
      success: 'Club info updated successfully'
    }
  },

  buttons: {
    save: 'Save changes',
    reset: 'Undo changes',
    removeLogo: 'Remove logo',
    uploadLogo: 'Upload logo'
  },

  clubInfo: {
    title: 'Edit club info',

    labels: {
      generalInfo: 'Club general info',
      telInfo: 'Tel info',
      financialInfo: 'Financial info',
      uploadLogo: 'Upload logo',
      preview: 'Preview:',
      name: 'Name',
      managerName: 'Manager name',
      country: 'Country',
      province: 'Province',
      city: 'City',
      address: 'Address',
      phoneNumber: 'Phone number',
      whatsAppSupport: 'whatsApp support',
      managerPhoneNumber: 'Manager phone number'
    },

    notifications: {
      logoUpload: {
        success: 'Logo uploaded successfully'
      },

      logoDelete: {
        success: 'Logo successfully removed'
      }
    },

    validators: {
      logoRule: 'Authorized JPG, JPEG or PNG formats. Maximum logo size 800 KB',
      imageSize: 'The selected file size is too large',
      fileFormat: 'The selected file format is not allowed',
    },

    description: {
      generalInfo: 'In this box, the general information of the club is displayed.'
    },

    edit: {
      title: 'Edit club'
    }
  },

  address: {
    title: 'Address',

    labels: {
      province: 'Province',
      city: 'City',
      address: 'Address'
    }
  },

  tel: {
    title: 'Call information',

    labels: {
      support: 'Support phone number',
      whatsApp: 'Support whatsApp number',
      sale: 'Sale phone number'
    }
  },

  message: {
    title: 'SMS panel',

    labels: {
      messangerKey: 'Authentication code',
      templates: 'Templates',
      messengerUsername: 'Username',
      messengerPassword: 'Password',
      messengerSenderNumber: 'Messenger sender number',
      enableCancelReserve: 'Enable cancel reserve message',
      enableDailyReport: 'Enable daily report message',
      enableNewReceiveByClub: 'Enable new receive by club  message',
      enableNewReceiveByCustomer: 'Enable new receive by customer message',
      enableNewReserveTime: 'Enable new reserve time message',
      newReserveTimeMessagePattern: 'Reserve time message pattern',
      enableNewReserveCoach: 'Enable new reserve coach message',
      enableWelcome: 'Enable welcome message'
    },

    invalidTexts: {
      onlyEnglish: 'Only English letters are allowed!',
    }
  },

  faq: {
    title: 'FAQ',

    labels: {
      question: 'Question',
      answer: 'Answer',
      delete: 'Delete question'
    },

    table: {
      header: {
        question: 'Question',
        answer: 'Answer'
      }
    },

    confirmations: {
      delete: {
        title: 'Confirm delete question',
        body: 'Are you sure you want to delete the {name} temporarily?'
      }
    },

    insert: {
      title: 'Insert FAQ'
    },

    edit: {
      title: 'Edit FAQ'
    }
  },

  rules: {
    title: 'Terms and conditions',

    labels: {
      rules: 'Terms and conditions'
    }
  },

  reset: {
    title: 'Reset system',

    labels: {
      type: 'Reset type',
      accounting: 'Accounting',
      allSystem: 'All system',
      sendVerifyCode: 'Send verify code'
    },

    descriptions: {
      accounting: 'Please note that all sales documents and invoices will be deleted upon approval',
      allSystem: 'Note that all system information, including the information of the treasury, accounting and club configuration, etc., will be deleted'
    },

    notifications: {
      unSaveChanges: 'You have unsaved settings. Please try again in a minute',
      otpWrong: 'The verify code is wrong',
      otpExpired: 'The verify code has expired',

      sendOtp: {
        success: 'The verify code has been sent to you',
        error: 'There was an error sending the verify code'
      },

      confirmReset: {
        success: 'The request has been sent and the reset will be applied in a few moments',
        error: 'The reset confirmation request has error'
      }
    }
  },

  access: {
    title: 'Access level',

    notifications: {
      insert: {
        success: 'Access level added successfully',
        error: 'Adding access level has error'
      },

      edit: {
        success: 'Access level successfully edited',
        error: 'Editing the access level has error'
      },

      delete: {
        success: 'Access level successfully deleted',
        error: 'The desired access level was not found.',
        cantDelete: 'The desired access level can not be deleted due to having a subgroup.'
      }
    },

    confirmations: {
      delete: {
        title: 'Delete access level',
        body: 'Are you sure you want to delete the {name}?'
      }
    },

    insert: {
      title: 'Add a new access level',

      labels: {
        name: 'Access level title ',
        allowedActiveSession: 'Active sessions allowed',
        access: 'access',
        insert: 'Insert',

        accessList: {
          manager: 'دسترسی مدیرکل',

          users: {
            totalAccess: 'دسترسی کامل اشخاص',
            show: 'مشاهده اشخاص',
            insert: 'افزودن شخص',
            update: 'ویرایش کامل',
            delete: 'حذف موقت  شخص',
            setRole: 'اختصاص نقش به شخص'
          },

          roles: 'دسترسی کامل نقش ها',
          treasury: {
            title: 'دسترسی کامل خزانه داری',

            bank: 'دسترسی کامل بانک ها',
            cash: 'دسترسی کامل صندوق ها'
          },

          reports: {
            title: 'دسترسی کامل گزارشات',
            logs: 'دسترسی لاگ ها'
          },

          delete: {
            title: 'حذف دائم',

            user: 'اشخاص',
            banks: 'بانک ها',
            cash: 'صندوق ها'
          }
        }
      },

      validators: {
        name: 'The access level name is not entered correctly',
        nameCantEmpty: 'Name cannot be empty',
        activeSessionCount: 'Active sessions should be less than equal {count}',
        access: 'No access selected'
      }
    },

    edit: {
      title: 'Edit access level'
    },

    table: {

      header: {
        row: 'row',
        name: 'Access title'
      }
    },

    actions: {
      insert: 'Insert',
      save: 'Save'
    }
  },

  users: {
    title: 'Users setting',

    labels: {
      gender: 'Gender is required'
    }
  },

  security: {
    title: 'Security',

    labels: {
      disActive: 'Duration of forced departure if there is no activity',
      minute: 'minute'
    }
  },

  customize: {
    title: 'Personalization system',

    labels: {
      theme: 'Select the desired theme'
    }
  },

  financialYear: {
    title: 'Fiscal Year',

    statusTypes: {
      all: 'All',
      active: 'Active',
      disActive: 'Inactive'
    },

    labels: {
      from: 'From ',
      to: ' to '
    },

    table: {
      header: {
        rowNumber: 'row',
        name: 'name',
        startDate: 'Start date',
        endDate: 'End date',
        status: 'Status',
        show: 'Show'
      }
    },

    notifications: {
      isActived: 'The fiscal year has already been activated',

      insert: {
        success: 'New fiscal year successfully registered',
        error: 'Creating a new fiscal year has error'
      },

      edit: {
        success: 'Fiscal year editing completed successfully',
        error: 'Editing fiscal year has error error'
      },

      switch: {
        success: 'Fiscal year view completed successfully',
        error: 'View fiscal year has error'
      },

      parseError: {
        period: 'The time period of the selected fiscal year is not valid',
        paymentGateways: 'The balance of a number of payment gateways has been unsettled!',
        storeroom: 'A number of stores requisition have not been approved!',
        periodNotEnded: 'The current fiscal year is not over!'
      }
    },

    confirmations: {
      insert: {
        title: 'Create fiscal year confirmation',
        body: 'Are you sure you want to create a new fiscal year?'
      },

      show: {
        title: 'Fiscal year view confirmation',
        body: 'Are you sure you want to view the {name} fiscal year?'
      }
    },

    edit: {
      title: 'Fiscal year configuration',

      periodTypes: {
        3: '3 months',
        6: '6 months',
        12: '1 year'
      },

      labels: {
        name: 'Name',
        startDate: 'Start Date',
        endDate: 'End date',
        period: 'Fiscal year interval'
      }
    }
  },

  financial: {
    title: 'Financial',

    wage: 'Wage',
    NegativeValue: 'Bank and fund can be negative',

    labels: {
      cardToCardWage: 'Card to card wage',
      ibanWage: 'IBAN wage'
    },

    notifications: {
      negativeBalanceError: 'Due to the negative balance of a number of banks or funds, the desired settings has error',
      wrongValues: 'The entered information is not valid',

      edit: {
        success: 'Financial information has been updated successfully',
        error: 'Update financial information has error'
      }
    }
  },

  General: {
    title: 'General',
    logo: {
      rule: 'Authorized JPG, JPEG or PNG formats. Maximum logo size 800 KB'
    },
    club: {
      code: 'Club code',
      name: 'Club name',
      managerName: 'Name and last name of the manager',
      address: 'Address',
      phoneNumber: 'Phone number',
      telSupport: 'Support phone number',
      whatsAppSupport: 'Support whatsApp number',
      managerPhoneNumber: 'Manager phone number'
    }
  },

  Treasury: {
    title: 'Treasury information',

    labels: {
      rule: 'Rules',
      treasuryCanNegative: 'The treasury can be negative.',
      NegativeValue: 'Banks and cash register can be negative'
    }
  },

  Locale: {
    title: 'Languages',
    language: 'Language',
    basicSetting: 'Basic settings',

    labels: {
      basicSetting: 'Base setting',
      defaultLanguage: 'Club default language',
      defaultCurrency: 'Club default currency',
      region: 'Club default region',
      defaultCountryCode: 'Club default country code'
    },

    currency: {
      IRT: 'IRT',
      IRR: 'IRR',
      USD: 'USD',
      AED: 'AED',
      other: 'other'
    },

    notifications: {
      edit: {
        success: 'Locale setting was successfully edited',
        error: 'Edit languages setting has error'
      }
    }
  },

  Store: {
    title: 'انبار'
  },

  Buy: {
    title: 'خرید'
  },

  Sale: {
    title: 'Sale'
  }
}