// users

export default {
  title: 'Users',

  table: {
    header: {
      row: 'Row',
      select: 'Select',
      image: 'Image',
      email: 'Email',
      city: 'Province/City',
      registryDate: 'Registry date',
      phoneNumber: 'Phone number',
      gender: 'Gender',
      name: 'Name and last name',
      creator: 'Creator',
      firstName: 'Name',
      lastName: 'Last name',
      score: 'Score',
      group: 'User group',
      company: 'Company',
      accessGroup: 'Access group',
      maxDebt: 'Credit',
      balance: 'Balance',
      address: 'Addresses',
      access: 'Accesses',
      status: 'Status',
      restore: 'Restore',
      delete: 'Delete'
    }
  },

  genderTypes: {
    all: 'All',
    man: 'mr.',
    dynamicMan: 'mr. ',
    woman: 'ms. '
  },

  statusTypes: {
    noSignUp: 'Unregistered',
    signUp: 'Start registration',
    confirmPhone: 'Phone number verification',
    complete: 'Completed'
  },

  user: {
    title: 'Users',

    labels: {
      all: 'All',
      unknown: 'Unknown',
      bySystem: 'By system',
      role: 'Access level',
      none: 'None',
      character: 'User group',
      gender: 'Gender',
      name: 'Name',
      family: 'Last name',
      phoneNumber: 'Phone number',
      delete: 'Delete user',
      customer: 'Customer',
      maxDebt: 'Credit',
      receive: 'Receive',
      cancel: 'Cancel',
      notHave: 'Not have'
    },

    notifications: {
      wrongValues: 'The information entered is incorrect',
      hasBalance: 'The person has a balance!',
      noAccessMaxDebt: 'You do not have access to credit!',

      insert: {
        success: 'User information successfully registered',
        error: 'Insert user information has error'
      },

      edit: {
        success: 'User information updated successfully',
        error: 'Editing user information has error'
      },

      delete: {
        success: 'The user information has been deleted',
        error: 'Deleting user information has error',
        isUsedError: 'The user can not be deleted due to the history of documents!'
      },

      restore: {
        success: 'User restored successfully',
        error: 'Restore user has error'
      },

      parseError: {
        name: 'The selected name is invalid!',
        family: 'The selected last name is invalid!',
        nationalCode: 'The entered national code is invalid!',
        birthDate: 'The selected birth date is invalid!',
        gender: 'The selected gender is not valid!',
        phoneNumber: 'The entered phone number is not valid or has been used before!',
        email: 'The entered email is not valid!',
        character: 'The selected customer group is not valid!',
        role: 'The selected access level is invalid!'
      }
    },

    validators: {
      name: 'The name must be English!',
      family: 'Last name must be English!',
    },

    confirmations: {
      delete: {
        title: 'Delete user confirmation',
        body: 'Are you sure you want to delete the {name} temporarily?'
      },

      forceDelete: {
        title: 'Delete user confirmation',
        body: 'Are you sure you want to delete the {name} permanently?'
      }
    },

    list: {
      title: 'Users list',

      sortTypes: {
        default: 'Default',
        name: 'name',
        debtor: 'Debtor',
        creditor: 'Creditor'
      },

      labels: {
        sortBaseOn: 'Order by'
      },
      searchResultEmpty: 'No results were found for your search!'
    },

    insert: {
      title: 'Insert new user',

      gender: 'Gender',
      man: 'Man',
      woman: 'Woman',
      name: 'Name',
      lastName: 'Last name',
      phoneNumber: 'Phone number',
      group: 'Group',
      balance: 'Balance',
      save: 'Save',
      delete: 'Delete'
    },

    edit: {
      title: 'Edit customer',
      dynamicTitle: 'Edit {name} information'
    },

    profile: {
      title: 'User profile',
      dynamicTitle: '{name} profile',

      actions: {
        transactions: 'Transactions',
        maxDebt: 'Credit',
        invoices: 'User invoices',
        coachClasses: 'Coach classes',
        reserve: 'Booking time',
        reserveSeason: 'Seasonal booking',
        receive: 'Receive from customer',
        logs: 'Activity logs'
      },

      asiderAvatar: {
        phoneNumber: 'Phone number: ',
        character: 'Customer group: ',
        todayDebt: 'Debt Today: '
      }
    },

    maxDebt: {
      title: 'Determining the maximum debt for customer',
      dynamicTitle: 'Determining the maximum debt for {name}'
    }
  },

  select: {
    title: 'Users list'
  },

  customers: {
    title: 'Customers',

    list: {
      title: 'Customers list'
    },

    insert: {
      title: 'Insert customer',
    },

    profile: {
      title: 'Customer profile',
    },

    edit: {
      title: 'Edit customer'
    },

    trash: {
      title: 'Removed customers list'
    }
  },

  openingBalance: {
    type: {
      debtor: 'Debtors to the club',
      creditor: 'Creditors of the club'
    },

    insert: {
      title: 'Insert {type} Opening balance',

      notifications: {
        debtor: {
          success: {
            title: 'Message',
            message: 'The opening balance of debtors was successfully registered'
          },

          error: {
            title: 'Error',
            message: 'Insert opening balance of debtors has error'
          }
        },

        creditor: {
          success: {
            title: 'Message',
            message: 'The opening balance of creditors was successfully registered'
          },

          error: {
            title: 'Error',
            message: 'Insert opening balance of creditors has error'
          }
        },

        locked: {
          title: 'Error',
          message: 'Opening balance is locked'
        },

        isExists: {
          title: 'Error',
          message: 'This value is in the list',
          dynamicMessage: '{name} exist in the list'
        }
      }
    },

    edit: {
      title: 'Edit {user} opening balance',

      labels: {
        price: 'Price',
        delete: 'Delete opening balance'
      },

      confirmations: {
        delete: {
          title: 'Delete confirmation',
          body: 'Are you sure you want to delete user opening balance?'
        }
      },

      notifications: {
        debtor: {
          update: {
            success: {
              title: 'Message',
              message: 'The opening balance of debtors was successfully updated'
            },

            error: {
              title: 'Error',
              message: 'Edit opening balance of debtors has error'
            }
          },

          delete: {
            success: {
              title: 'Message',
              message: 'The opening balance of debtors was successfully deleted'
            },

            error: {
              title: 'Error',
              message: 'Delete opening balance of debtors has error'
            }
          }
        },

        creditor: {
          update: {
            success: {
              title: 'Message',
              message: 'The opening balance of creditors was successfully updated'
            },

            error: {
              title: 'Error',
              message: 'Edit opening balance of creditors has error'
            }
          },

          delete: {
            success: {
              title: 'Message',
              message: 'The opening balance of creditors was successfully deleted'
            },

            error: {
              title: 'Error',
              message: 'Delete opening balance of creditors has error'
            }
          }
        }
      }
    },

    list: {
      table: {
        header: {
          delete: 'Delete',
          price: 'Price',
          name: 'Name and last name',
          rowNumber: 'Row'
        }
      }
    }
  },

  transactions: {
    title: 'User transactions',
    dynamicTitle: '{name} transactions',

    table: {
      header: {
        docNumber: 'Document number',
        date: 'Date',
        documentation: 'Description',
        price: 'Price',
        balance: 'Balance'
      }
    },

    types: {
      sale: 'Booking',
      purchase: 'Purchase',
      receive: 'Receive'
    },

    notifications: {
      print: {
        error: 'The print request was an error'
      }
    }
  },

  invoices: {
    title: 'User Invoices list',
    dynamicTitle: '{name} invoices list',

    labels: {
      until: ' to ',
      active: 'Active',
      passed: 'Past',
      reserved: 'Booked',
      canceled: 'Canceled',
      fromNow: 'From today on'
    },

    table: {
      header: {
        docNumber: 'Invoice number',
        creator: 'Creator',
        reserveDate: 'Booking date',
        date: 'Date',
        time: 'Booking time',
        courtName: 'Booking court',
        finalPrice: 'Final price',
        status: 'Status',
      }
    },

    statusTypes: {
      all: 'All',
      active: 'Active booking',
      activePassed: 'Used Booking',
      canceled: 'Canceled',
      canceledBySystem: 'Canceled by system'
    },

    processTypes: {
      preInvoice: 'Pre-invoice booking',
      invoice: 'Invoice booking'
    },

    financialStatusTypes: {
      debtor: 'Debtor',
      checkout: 'Settlement'
    },

    actionTypes: {
      present: 'In-person reservation',
      tel: 'Phone reservation',
      internet: 'Online reservation'
    }
  },

  seasonSchedules: {
    title: 'Seasonal reservation list',
    dynamicTitle: '{gender} {name} seasonal reservation list',

    table: {
      header: {
        row: 'Row',
        creator: 'Creator',
        quantity: 'Number of invoices',
        startDate: 'Start date',
        endDate: 'End date',
        createdAt: 'Created date'
      }
    }
  },

  logs: {
    title: 'Personal Logs'
  },

  actions: {
    insert: 'Insert',
    edit: 'Edit',
    save: 'Save',
    delete: 'Delete'
  }
}