<template>
  <div class="custom-input custom-profile-image-input">
    <label class="profile-image-label" :class="[{'is-not-valid': !data.isValid}, classes || {}]" :style="getStyles">
      <span class="input-label" :class="{'is-focus': data.value.length || label.length}">{{ labelPlaceholder || label }}</span>
      <div class="upload-label">
        <img class="upload-image"
             :src="data.value ? getAvatarUrl(data.value) : defaultImage ? defaultImage : genderId === 1 ? require('@/assets/images/man-avatar.png') : require('@/assets/images/woman-avatar.png')"
             alt="">
      </div>
      <label v-if="!disabled" for="profileImageInput" class="btn upload-btn">
        <font-awesome-icon v-if="data.value.length === 0" class="new-suggest-modal-button"
                           icon="plus"/>
        <custom-icon v-else
                     class="edit-suggest-modal-button"
                     color-code="#ffffff"
                     height="17px"
                     width="17px"
                     icon="EDIT"/>
      </label>
      <input type="file"
             id="profileImageInput"
             v-if="!disabled"
             :accept="acceptType"
             :class="[align === 'right' ? 'text-left' : 'text-right', {'disabled': disabled}, {'rtl': !rtl}, {'ltr': rtl}]"
             @change="handleInputValue"
             @click="$emit('click')"
             :placeholder="placeholder || ''"
             :disabled="disabled">

      <button v-if="data.value.length > 0 && !disabled" class="btn cancel-btn" @click="handleRemoveInput">
        <font-awesome-icon class="new-suggest-modal-button"
                           icon="minus"/>
      </button>
    </label>
  </div>
</template>

<script>
  import CustomIcon from "../customIcon/customIcon";
  import {getAvatarUrl, getStaticFileUrl} from "../../assets/js/functions";
  import {uploadAssets} from "@/http/requests/avatar";

  export default {
    name: 'CustomProfileImageInput',
    components: {CustomIcon},
    model: {
      prop: 'value',
      event: 'input'
    },
    props: {
      rtl: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      disabled: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      genderId: {
        type: Number,
        default: () => {
          return 1
        }
      },
      label: {
        type: String,
        default: () => {
          return ''
        }
      },
      placeholder: {
        type: String,
        default: () => {
          return ''
        }
      },
      labelPlaceholder: {
        type: String,
        default: () => {
          return ''
        }
      },
      align: {
        type: String,
        default: () => {
          return 'left'
        }
      },
      isEmpty: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      regex: {
        type: RegExp,
        default: () => {
          return null
        }
      },
      value: {
        type: Object,
        default() {
          return {}
        }
      },
      classes: {
        type: Object,
        default() {
          return {}
        }
      },
      styles: {
        type: Object,
        default() {
          return {}
        }
      },
      acceptType: {
        type: String,
        default: () => {
          return 'image/*'
        }
      },
      defaultImage: {
        type: String,
        default: () => {
          return ''
        }
      },
      type: {
        type: String,
        validator(type) {
          return ['avatars', 'club'].indexOf(type) >= 0;
        },
        default: () => {
          return 'avatars'
        }
      }
    },
    data() {
      return {
        data: {
          value: '',
          isValid: false
        }
      }
    },
    computed: {
      getStyles() {
        const styles = JSON.parse(JSON.stringify(this.styles))
        return styles
      }
    },
    created() {
      if (Object.keys(this.value).length) {
        this.initValues()
      }
      if (this.isEmpty) {
        this.data.isValid = true
      } else {
        this.validateValue()
      }
    },
    methods: {
      handleInputValue(e) {
        this.data.value = e.target.files[0]
        this.data.isValid = true
        if (this.data.value.size <= 512000) {
          let formData = new FormData()
          formData.append('avatar', this.data.value);
          this.validateValue()
          uploadAssets(formData, this.type).then((res) => {
            this.$emit('input', {value: res.data.path, isValid: this.data.isValid})
          })
        } else {
          this.$vs.notify({
            title: 'خطا',
            text: 'حجم عکس باید کمتر از 512 کیلوبایت باشد!',
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
        }
      },
      validateValue() {
        // if (this.regex !== null) {
        //     this.data.isValid = this.regex.test(this.data.value)
        // } else if (this.isEmpty) {
        //     this.data.isValid = true
        // } else {
        //     this.data.isValid = this.data.value.toString().length > 0
        // }
      },
      initValues() {
        this.data.value = this.value.value || ''
        this.validateValue()
      },

      handleRemoveInput (event) {
        event.preventDefault()
        this.$emit('input', {value: '', isValid: true})
      },

      getAvatarUrl(value) {
        return getAvatarUrl(value)
      },

      getStaticFileUrl(value) {
        return getStaticFileUrl(value)
      }
    },
    watch: {
      value: {
        handler() {
          this.initValues()
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .custom-profile-image-input {
    position: relative;
    /*margin: 15px 0;*/

    .profile-image-label {
      display: block;
      position: relative;
      padding: 6px 10px 6px 5px;
      border: 1px solid #cecece;
      border-radius: 50%;
      backdrop-filter: sepia(1);
      transition: all .3s ease;
      height: 150px;
      width: 150px;
      margin: auto;

      &.is-not-valid {
        /*border-color: #b00000;*/
        color: #b00000;

        input {
          color: #b00000;
        }
      }

      span {
        position: absolute;
        padding: 0 5px;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -o-transition: all .3s ease;
        -ms-transition: all .3s ease;
        transition: all .3s ease;
        top: 7px;

        &.is-focus {
          font-size: 12px;
          background: #ffffff;
          top: -10px;
        }
      }

      input {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: transparent;
        border: none;
        outline: none !important;
        text-align: right;
        opacity: 0;

        &:hover {
          cursor: pointer;
        }

        &.disabled {
          opacity: 0.75;
        }
      }

      .upload-label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        padding: 0;
        border-radius: 50%;
        overflow: hidden;

        .upload-image {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .btn {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0;
        color: #ffffff;
        background-color: #888888;
        border: 0;
        font-size: 20px;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        z-index: 0;

        &:hover {
          cursor: pointer;
        }
      }

      .upload-btn {
        left: 0;

        .edit-suggest-modal-button {
        }
      }

      .cancel-btn {
        right: 0;
      }
    }
  }
</style>
