import axios from '@/http/axios'
import {createCancelTokenHandler} from "../axios/utils";

const cancelTokenHandlerObject = {
    getCharacters: createCancelTokenHandler('getCharacters'),
    getCharacterUsersList: createCancelTokenHandler('getCharacterUsersList'),
    getCharactersTrash: createCancelTokenHandler('getCharactersTrash'),
    getCharacterActivitiesLog: createCancelTokenHandler('getCharacterActivitiesLog')
}

function makeQuery (page = 1, filters = [], sorts = []) {
    let queries = []

    if (page > 1) queries.push(`page=${page}`)

    if (filters.length) queries = queries.concat(...filters)

    if (sorts.length) queries = queries.concat(...sorts)

    return queries
}


export function getCharacters (page, filters = [], sorts = []) {
    let queries = ''
    if (page > 1) {
        queries += `page=${page}`
    }

    if (filters.length) {
        if (queries.length > 0) queries += '&'

        queries += filters.join('&')
    }

    if (sorts.length) {
        if (queries.length > 0) queries += '&'

        queries += sorts.join('&')
    }

    if (queries.length > 0) queries = `?${  queries}`

    return axios({
        url: `v1/admin/characters${queries}`,
        method: 'get',
        // setting a cancel token and canceling the previous request of this type
        cancelToken: cancelTokenHandlerObject['getCharacters'].handleRequestCancellation().token
    })
}

export function getCharacter (id) {
    return axios({
        url: `v1/admin/characters/${id}`,
        method: 'get'
    })
}



export function getCharacterUsersList (page, id) {
    let queries = ''
    if (page > 1) {
        queries += `page=${page}`
    }
    if (queries.length > 0) queries = `?${  queries}`

    return axios({
        url: `v1/admin/characters/${id}/users${queries}`,
        method: 'get',
        // setting a cancel token and canceling the previous request of this type
        cancelToken: cancelTokenHandlerObject['getCharacterUsersList'].handleRequestCancellation().token
    })
}

export function getCharactersTrash () {
    return axios({
        url: 'v1/admin/characters?trashed=true',
        method: 'get',
        // setting a cancel token and canceling the previous request of this type
        cancelToken: cancelTokenHandlerObject['getCharactersTrash'].handleRequestCancellation().token
    })
}

export function getCharacterActivitiesLog (id, page, filters = [], sorts = []) {
    let queries = makeQuery(page, filters, sorts)

    if (queries.length > 0) queries = `?${  queries.join('&')}`
    return axios({
        url: `v1/admin/characters/${id}/activity-logs${queries}`,
        method: 'get',
        // setting a cancel token and canceling the previous request of this type
        cancelToken: cancelTokenHandlerObject['getCharacterActivitiesLog'].handleRequestCancellation().token
    })
}

export function insertCharacter (payment) {
    return axios({
        url: 'v1/admin/characters',
        data: payment,
        method: 'post'
    })
}

export function insertUserToCharacter (id, payment) {
    return axios({
        url: `v1/admin/characters/${id}/users`,
        data: payment,
        method: 'post'
    })
}


export function editCharacter (id, payment) {
    return axios.patch(`v1/admin/characters/${id}`, payment)
}

export function deleteCharacter (id, force = false) {
    return axios({
        url: `v1/admin/characters/${id}${force ? '/force' : ''}`,
        method: 'delete'
    })
}

export function removeUserCharacter (id, payment) {
    return axios({
        url: `v1/admin/characters/${id}/users`,
        data: payment,
        method: 'delete'
    })
}

export function restoreCharacter (id) {
    return axios({
        url: `v1/admin/characters/${id}/restore`,
        method: 'put'
    })
}
