<template>
  <div class="edit-banks-opening-balance">

    <div class="edit-banks-opening-balance-box">

      <vs-row class="my-2">
        <vs-col class="w-full">
          <vs-input class="number-style w-full"
                    v-model="updatedBank.price"
                    :label="`${$t('treasury.banks.openingBalance.edit.labels.price')}`"/>
        </vs-col>
      </vs-row>

      <vs-row class="my-2">
        <vs-col class="w-full">
          <vs-button class="w-full"
                     color="danger"
                     @click="$refs.deleteOpeningBalanceConfirmation.showDialog()">
            {{ $t('treasury.banks.openingBalance.edit.labels.delete') }}
          </vs-button>
        </vs-col>
      </vs-row>
    </div>

    <custom-dialog ref="deleteOpeningBalanceConfirmation"
                   :title="`${$t('treasury.banks.openingBalance.edit.confirmations.delete.title')}`"
                   :body="`${$t('treasury.banks.openingBalance.edit.confirmations.delete.body')}`"
                   @accept="handleDeleteBankOpeningBalance"/>

    <button v-show="false" id="saveBTN" @click="sendData"/>
  </div>
</template>

<script>
import axios from 'axios'
import {addComma} from '@/assets/js/functions'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import {
  deleteBanksOpeningInventories,
  setBanksOpeningInventories
} from '../../../../../http/requests/accountancy/openingInventories'
import CustomDialog from '../../../../../components/customDialog/customDialog'

export default {
  name: 'editBanksOpeningBalance',
  components: {CustomDialog, CustomIcon},
  props: {
    bank: {}
  },
  data () {
    return {
      updatedBank: {}
    }
  },
  created () {
    this.updatedBank = JSON.parse(JSON.stringify(this.bank))
  },
  methods: {
    sendData () {
      const bank = {
        id: this.updatedBank.id,
        price: parseInt(this.updatedBank.price.toString().replaceAll(',', ''))
      }
      setBanksOpeningInventories([bank]).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.banks.openingBalance.notifications.edit.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$store.dispatch('helper/changeOpeningBalance')
      }).catch((error) => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.banks.openingBalance.notifications.edit.error'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      })
    },
    handleDeleteBankOpeningBalance () {
      if (this.bank && this.bank.id) {
        deleteBanksOpeningInventories(this.bank.id).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('treasury.banks.openingBalance.notifications.delete.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })

          this.$store.dispatch('helper/deleteOpeningBalance')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('treasury.banks.openingBalance.notifications.delete.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
      }
    }
  },
  watch: {
    'updatedBank.price': {
      handler (val) {
        this.updatedBank.price = addComma(val)
      }
    }
  }
}
</script>

<style lang="scss">
.select-file-input {
  width: 100%;
  background: #28c76f;
  display: block;
  padding: 10px;
  border-radius: .5rem;

  span {
    color: white !important;

    div.icon {
      background-color: white !important;
    }
  }

  input {
    display: none;
  }
}
</style>
