import Vue from 'vue'
import Router from 'vue-router'
import {afterEach} from '@/router/afterEach'
import {beforeEach} from '@/router/beforeEach'

// import pages routes
import home from './routes/home'
import profile from './routes/profile'
import events from './routes/events'
import customers from './routes/customers'
import booking from './routes/booking'
import treasury from './routes/treasury'
import accountancy from './routes/accountancy'
import companies from './routes/companies'
import coaches from './routes/coaches'
import club from './routes/club'
import characters from './routes/characters'
import tickets from './routes/tickets'
import reports from './routes/reports'
import settings from './routes/settings'
import backup from './routes/backup'
import smsPanel from './routes/smsPanel'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('../layouts/main/Main.vue'),
      meta: {requiresAuth: true},
      children: [
        home,
        profile,
        events,
        customers,
        coaches,
        club,
        characters,
        booking,
        treasury,
        accountancy,
        companies,
        backup,
        tickets,
        settings,
        reports,
        smsPanel
        /*{
          path: '/test',
          name: 'test',
          component: () => import('../views/pages/test'),
          meta: {
            pageTitle: 'test',
            pageTitleI18n: ''
            // rule: 'editor'
          },
        },*/
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'page-login',
          component: () => import('@/views/pages/Login.vue')
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },
        {
          path: '/sales/invoices/:id/print',
          name: 'print-sale-invoice-page',
          component: () => import('@/views/admin/sales/invoices/print/printInvoice.vue')
        },
        {
          path: '/treasury/payment/:id/print',
          name: 'print-payment-page',
          component: () => import('@/views/admin/accountancy/documents/print/PrintPayment.vue')
        },
        {
          path: '/treasury/receive/:id/print',
          name: 'print-receive-page',
          component: () => import('@/views/admin/accountancy/documents/print/PrintReceive.vue')
        },
        {
          path: '/treasury/internal-funds-transfers/:id/print',
          name: 'print-internal-funds-transfer-page',
          component: () => import('@/views/admin/accountancy/documents/print/PrintInternalFundsTransfer.vue')
        },
        {
          path: '/treasury/cost/:id/print',
          name: 'print-cost-page',
          component: () => import('@/views/admin/accountancy/documents/print/PrintCost.vue')
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

router.afterEach(afterEach)

export default router

router.beforeEach(beforeEach)
