export default {
  path: 'receive',
  component: () => import('../../../views/admin/treasury/receive/index.vue'),
  children: [
    {
      path: '',
      name: 'treasuryReceive',
      component: () => import('../../../views/admin/treasury/receive/list/receivesList.vue'),
      meta: {
        pageTitle: 'دریافت',
        pageTitleI18n: 'treasury.receive.title',
        permission: 'receive.show'
        // rule: 'editor'
      }
    },
    {
      path: 'insert',
      name: 'insertTreasuryReceive',
      component: () => import('../../../views/admin/treasury/receive/insert/insertReceive.vue'),
      meta: {
        pageTitle: 'دریافت',
        pageTitleI18n: 'treasury.receive.insert.title',
        permission: 'receive.create'
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      name: 'treasuryReceiveDocument',
      component: () => import('../../../views/admin/treasury/receive/document/receiveDocument.vue'),
      meta: {
        pageTitle: 'سند دریافت',
        pageTitleI18n: 'treasury.receive.title',
        permission: 'receive.show'
        // rule: 'editor'
      }
    },
    {
      path: 'doc-:id/activities-log',
      name: 'receiveActivitiesLog',
      component: () => import(/* webpackChunkName: "receiveActivitiesLog" */ '../../../views/admin/treasury/receive/activitiesLog/receiveActivitiesLog.vue'),
      meta: {
        permission: 'activity_logs.show',
        pageTitle: 'لاگ های دریافت',
        pageTitleI18n: 'treasury.receive.logs.title'
        // rule: 'editor'
      }
    },
  ]
}