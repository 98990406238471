import axios from '@/http/axios'
import store from '../../../store/store'
import {createCancelTokenHandler} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getProfileInvoices: createCancelTokenHandler('getProfileInvoices'),
  getProfileReceivedEvents: createCancelTokenHandler('getProfileReceivedEvents'),
  getProfileSentEvents: createCancelTokenHandler('getProfileSentEvents'),
  getUsers: createCancelTokenHandler('getUsers'),
  getUsersTrash: createCancelTokenHandler('getUsersTrash'),
  getUserTransaction: createCancelTokenHandler('getUserTransaction'),
  getUserInvoices: createCancelTokenHandler('getUserInvoices'),
  getUserSeasonSchedules: createCancelTokenHandler('getUserSeasonSchedules'),
  getUserClasses: createCancelTokenHandler('getUserClasses')
}

function makeQuery (page = 1, filters = [], sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

/* Profile */
export function getProfile () {
  return axios({
    url: 'v1/admin/profile',
    method: 'get'
  })
}

export function editProfile (payment) {
  return axios.patch('v1/profile', payment)
}

export function getProfileInvoices (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/profile/sale-invoices${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProfileInvoices'].handleRequestCancellation().token
  })
}

export function getProfileReceivedEvents (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/profile/received-events${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProfileReceivedEvents'].handleRequestCancellation().token
  })
}

export function getProfileSentEvents (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/profile/sent-events${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProfileSentEvents'].handleRequestCancellation().token
  })
}

export function editProfileBrowserSettings () {
  const setting = {
      tables: store.state.table.tables,
      // tables: store.state.table.tables.storesListTable ? store.state.table.tables.storesListTable : [],
      theme: store.state.theme
  }
  delete setting.tables.tables
  delete setting.tables.theme
  return axios.patch('v1/admin/profile/browser-settings', { settings: JSON.stringify(setting) })
}

/* Users */

export function getUsers (page, filters = [], sorts = []) {
  let queries = ''
  if (page > 1) {
    queries += `page=${page}`
  }

  if (filters.length) {
    if (queries.length > 0) queries += '&'

    queries += filters.join('&')
  }

  if (sorts.length) {
    if (queries.length > 0) queries += '&'

    queries += sorts.join('&')
  }

  if (queries.length > 0) queries = `?${  queries}`

  return axios({
    url: `v1/admin/users${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getUsers'].handleRequestCancellation().token
  })
}

export function getUsersTrash (page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/users?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getUsersTrash'].handleRequestCancellation().token
  })
}

export function getUser (id) {
  return axios({
    url: `v1/admin/users/${id}`,
    method: 'get'
  })
}

export function getUserSaleAddons (id) {
  return axios({
    url: `v1/admin/users/${id}/active-sale-addons`,
    method: 'get'
  })
}

export function getUserBrowserSettings (id) {
  return axios({
    url: `v1/admin/users/${id}/browser-settings`,
    method: 'get'
  })
}

export function insertUserBrowserSettings (setting) {
  const userId = setting.id
  delete setting.id

  return axios({
    url: `v1/admin/users/${userId}/browser-settings`,
    data: setting,
    method: 'post'
  })
}

export function editUserBrowserSettings (setting) {
  const userId = setting.id
  delete setting.id

  return axios.patch(`v1/admin/users/${userId}/browser-settings`, setting)
}

export function getUserActivitiesLog (id) {
  return axios({
    url: `v1/admin/users/${id}/activity-logs`,
    method: 'get'
  })
}

export function insertUser (payment) {
  return axios({
    url: 'v1/admin/users',
    data: payment,
    method: 'post'
  })
}

export function editUser (id, payment) {
  return axios.patch(`v1/admin/users/${id}`, payment)
}

export function deleteUser (id, force = false) {
  return axios({
    url: `v1/admin/users/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreUser (id) {
  return axios({
    url: `v1/admin/users/${id}/restore`,
    method: 'put'
  })
}

export function receiveFromUser (payment) {
  return axios({
    url: `v1/admin/users/${payment.id}/receive`,
    data: payment,
    method: 'post'
  })
}

export function paymentToUser (payment) {
  return axios({
    url: `v1/admin/users/${payment.id}/payment`,
    data: payment,
    method: 'post'
  })
}

export function getUserTransaction (userId, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/users/${userId}/transactions${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getUserTransaction'].handleRequestCancellation().token
  })
}

export function printUserTransactions (userId, filters = [], sorts = []) {
  let queries = []

  if (filters.length) queries = queries.concat(...filters)

  if (sorts.length) queries = queries.concat(...sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/admin/users/${userId}/transactions?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getUserInvoices (userId, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/users/${userId}/sale-invoices${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getUserInvoices'].handleRequestCancellation().token
  })
}

export function getUserSeasonSchedules (id, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/users/${id}/season-schedules${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getUserSeasonSchedules'].handleRequestCancellation().token
  })
}

export function getUserClasses (id, page, filters = [], sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/users/${id}/classes${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getUserClasses'].handleRequestCancellation().token
  })
}

/* User Addresses */

export function getUserAddresses (userId) {
  return axios({
    url: `v1/admin/users/${userId}/addresses`,
    method: 'get'
  })
}

export function insertUserAddress (userId, address) {
  return axios({
    url: `v1/admin/users/${userId}/addresses`,
    data: address,
    method: 'post'
  })
}

export function editUserAddress (userId, addressId, address) {
  return axios.patch(`v1/admin/users/${userId}/addresses/${addressId}`, address)
}

export function deleteUserAddress (userId, addressId) {
  return axios({
    url: `v1/admin/users/${userId}/addresses/${addressId}`,
    method: 'delete'
  })
}
