<template>
  <vs-button v-if="iconPack === 'useral'"
             type="filled"
             class="action-button useral m-auto"
             style="padding: 2px !important;"
             :color="color ? color : 'primary'"
             @click="buttonClicked">
    <custom-icon :icon="icon" height="20px" width="20px" color-code="#ffffff" />
  </vs-button>

  <vs-button v-else-if="showActions"
             class="action-button m-auto"
             type="filled"
             :icon="icon ? icon : ''"
             :icon-pack="iconPack ? iconPack : ''"
             :color="color ? color : 'primary'"
             @click="buttonClicked">
    <span v-if="text">{{ text }}</span>
  </vs-button>
</template>

<script>
import CustomIcon from "../customIcon/customIcon";
export default {
  name: 'actionsBox',
  components: {CustomIcon},
  props: {
    show: '',
    lookField: '',
    color: '',
    icon: '',
    iconPack: '',
    text: '',
    type: '',
    data: {}
  },
  data () {
    return {
      lookFields: [],
      firstValue: {},
      showActions: false
    }
  },
  created () {
    if (typeof this.lookField === 'string') {
      this.lookFields = [this.lookField]
    } else if (Array.isArray(this.lookField)) {
      this.lookFields = this.lookField
    }

    switch (this.show) {
    case 'always':
    case 'hideAfterClick':
      this.showActions = true
      break

    case 'onchange':
      this.lookFields.forEach((field) => {
        this.firstValue[field] = this.data[field]
      })
      break
    }
  },
  methods: {
    buttonClicked (event) {
      event.stopPropagation()
      event.preventDefault()
      setTimeout(() => {

        switch (this.type) {
        case 'reset_input':
          this.lookFields.forEach((field) => {
            this.firstValue[field] = this.data[field]
          })
          this.showActions = false
          break

        case 'button':
          break

        case 'hideAfterClick':
          this.showActions = false
          break

        default:
          this.lookFields.forEach((field) => {
            this.firstValue[field] = this.data[field]
          })
          this.showActions = false
          break
        }

        this.$emit('clicked')
      }, 500)
    }
  },
  watch: {
    data: {
      handler (val) {
        let checkStatus = 0

        switch (this.show) {
        case 'hideAfterClick':
          break

        case 'always':
          this.showActions = true
          break

        case 'onchange':
          this.lookFields.forEach((field) => {
            if (val[field] !== this.firstValue[field]) {
              checkStatus++
            }
          })

          this.showActions = checkStatus > 0
          break
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.action-button {
  width: 30px;
  height: 30px;
  padding: 0 !important;

  &.useral {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
