import axios from '@/http/axios'

export function getPermissions () {
  return axios({
    url: 'v1/admin/settings/permissions',
    method: 'get'
  })
}

export function getRoles () {
  return axios({
    url: 'v1/admin/settings/roles',
    method: 'get'
  })
}

export function insertRole (role) {
  return axios({
    url: 'v1/admin/settings/roles',
    data: role,
    method: 'post'
  })
}

export function editRole (role) {
  const id = role.id
  delete role.id
  return axios.patch(`v1/admin/settings/roles/${id}`, role)
}

export function deleteRole (id) {
  return axios({
    url: `v1/admin/settings/roles/${id}`,
    method: 'delete'
  })
}
