// documents

export default {
  title: 'Documents',

  document: {
    title: 'Document',

    table: {

      header: {
        row: 'Row',
        docNumber: 'Document number',
        date: 'Date',
        reference: 'Reference',
        details: 'Details',
        price: 'Price',
        type: 'Type'
      }
    }
  }
}