export default {
  path: 'banks',
  component: () => import('../../../views/admin/treasury/banks/index.vue'),
  children: [
    {
      path: '/',
      name: 'treasuryBanks',
      component: () => import('../../../views/admin/treasury/banks/list/banksList.vue'),
      meta: {
        permission: 'bank.show',
        pageTitle: 'بانک ها',
        pageTitleI18n: 'treasury.banks.title'
        // rule: 'editor'
      }
    },
    {
      path: 'insert',
      name: 'insertTreasuryBank',
      component: () => import('../../../views/admin/treasury/banks/insert/insertBank.vue'),
      meta: {
        permission: 'bank.create',
        pageTitle: 'افزودن بانک',
        pageTitleI18n: 'treasury.banks.insert.title'
        // rule: 'editor'
      }
    },
    {
      path: 'trash',
      name: 'treasuryBankTrash',
      component: () => import('../../../views/admin/treasury/banks/trash/banksTrash.vue'),
      meta: {
        permission: ['bank.show', 'bank.delete'],
        pageTitle: 'سطل زباله بانک ها',
        pageTitleI18n: ''
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      component: () => import('../../../views/admin/treasury/banks/bank/index.vue'),
      children: [
        {
          path: '/',
          name: 'treasuryBank',
          component: () => import('../../../views/admin/treasury/banks/bank/menu/bankMenu.vue'),
          meta: {
            permission: 'bank.show',
            pageTitle: 'بانک',
            pageTitleI18n: 'treasury.banks.bank.title'
            // rule: 'editor'
          }
        },
        {
          path: 'edit',
          name: 'editTreasuryBank',
          component: () => import('../../../views/admin/treasury/banks/bank/edit/editBank.vue'),
          meta: {
            permission: 'bank.update',
            pageTitle: 'ویرایش بانک',
            pageTitleI18n: 'treasury.banks.edit.title'
            // rule: 'editor'
          }
        },
        {
          path: 'transactions',
          name: 'transactionsTreasuryBank',
          component: () => import('../../../views/admin/treasury/banks/bank/transactions/bankTransactions.vue'),
          meta: {
            permission: 'bank.show',
            pageTitle: 'گزارشات بانک',
            pageTitleI18n: ''
            // rule: 'editor'
          }
        },
        {
          path: 'activities-log',
          name: 'BankActivitiesLog',
          component: () => import(/* webpackChunkName: "bankActivitiesLog" */ '../../../views/admin/treasury/banks/bank/activitiesLog/bankActivitiesLog.vue'),
          meta: {
            permission: 'activity_logs.show',
            pageTitle: 'لاگ های بانک',
            pageTitleI18n: 'treasury.banks.logs.title'
            // rule: 'editor'
          }
        }
        /*{
          path: 'transactions/:transId',
          name: 'treasuryBankDocument',
          component: () => import('../../../views/admin/documents/document.vue'),
          meta: {
            permission: 'bank.show',
            pageTitle: 'گزارشات بانک',
            pageTitleI18n: ''
            // rule: 'editor'
          }
        }*/
      ]
    }
  ]
}