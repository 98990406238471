export default {
  path: 'internal-funds-transfers',
  component: () => import('../../../views/admin/treasury/InternalFundsTransfer/index.vue'),
  children: [
    {
      path: '',
      name: 'treasuryInternalFundsTransfers',
      component: () => import('../../../views/admin/treasury/InternalFundsTransfer/list/internalFundsTransfersList.vue'),
      meta: {
        pageTitle: 'انتقالی خزانه',
        pageTitleI18n: 'treasury.internalFundsTransfer.title',
        permission: 'internal_fund.show'
        // rule: 'editor'
      }
    },
    {
      path: 'insert',
      name: 'insertTreasuryInternalFundsTransfers',
      component: () => import('../../../views/admin/treasury/InternalFundsTransfer/insert/insertInternalFundsTransfer.vue'),
      meta: {
        pageTitle: 'انتقالی خزانه جدید',
        pageTitleI18n: 'treasury.internalFundsTransfer.insert.title',
        permission: 'internal_fund.create'
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      name: 'treasuryInternalFundsTransferDocument',
      component: () => import('../../../views/admin/treasury/InternalFundsTransfer/document/internalFundsTransferDocument.vue'),
      meta: {
        pageTitle: 'انتقالی خزانه',
        pageTitleI18n: 'treasury.internalFundsTransfer.title',
        permission: 'internal_fund.show'
        // rule: 'editor'
      }
    },
    {
      path: 'doc-:id/activities-log',
      name: 'internalFundsTransfersActivitiesLog',
      component: () => import(/* webpackChunkName: "internalFundActivitiesLog" */ '../../../views/admin/treasury/InternalFundsTransfer/activitiesLog/internalFundActivitiesLog.vue'),
      meta: {
        permission: 'activity_logs.show',
        pageTitle: 'لاگ های انتقالی خرانه',
        pageTitleI18n: 'treasury.internalFundsTransfer.logs.title'
        // rule: 'editor'
      }
    }
  ]
}