<template>
  <div class="u-input custom-validate-input">
    <div class="u-input-box" :class="[{'is-not-valid': !data.isValid || notValid, 'disabled': disabled}, classes || {}]" :style="getStyles">
<!--      <span class="input-label" :class="{'is-focus': data.value.toString().length || label.length}">{{ labelPlaceholder || label }}</span>-->

      <div class="u-input-label-box">
        <div class="u-input-label-box-start"></div>
        <div v-if="labelPlaceholder || label" class="u-input-label-box-center" :class="[labelPosition]">
          <span class="text-subtitle" :class="[{'is-focus': data.value.toString().length || label, 'text-body-1': labelPosition === 'inside'}]">{{ labelPlaceholder || label }}</span>
        </div>
        <div class="u-input-label-box-end"></div>
      </div>
      <input type="text"
             :id="id"
             :class="[align === 'right' ? 'text-left' : 'text-right', {'disabled': disabled}, {'rtl': !rtl}, {'ltr': rtl}]"
             :style="inputStyles"
             @input="handleInputValue, $emit('input', data)"
             @click="$emit('click')"
             @keydown.enter="handleKeydownEnter"
             :placeholder="placeholder || ''"
             :disabled="disabled"
             :autocomplete="autoComplete"
             v-model="data.value">
    </div>
    <span class="u-input-errors" v-if="!data.isValid && this.data.value.length > 0" >
      {{ invalidText || this.$t('customValidateInput.invalidText') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'customValidateInput',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    rtl: {
      type: Boolean,
      default: () => { return true }
    },
    id: {
      type: String,
      default: () => { return '' }
    },
    disabled: {
      type: Boolean,
      default: () => { return false }
    },
    autoComplete: {
      type: Boolean,
        default: () => { return false }
    },
    label: {
      type: String,
      default: () => { return '' }
    },
    placeholder: {
      type: String,
      default: () => { return '' }
    },
    labelPlaceholder: {
      type: String,
      default: () => { return '' }
    },
    labelPosition: {
      type: String,
      default: 'outline'
    },
    align: {
      type: String,
      default: () => { return 'right' }
    },
    isEmpty: {
      type: Boolean,
      default: () => { return false }
    },
    notValid: {
      type: Boolean,
      default: () => { return false }
    },
    regex: {
      type: RegExp,
      default: () => { return null }
    },
    invalidText: {
        type: String,
        default: () => { return '' }
    },
    value: {
      type: Object,
      default () { return {} }
    },
    classes: {
      type: Object,
      default () { return {} }
    },
    styles: {
      type: Object,
      default () { return {} }
    },
    inputStyles: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      data: {
        value: '',
        isValid: false
      }
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))
      if (!styles.width) {
        // styles.width = '230px'
      }

      return styles
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    if (this.isEmpty && this.data.value === '') {
      this.data.isValid = true
    } else {
      this.validateValue()
    }
  },
  methods: {
    handleInputValue () {
      this.data.value = this.data.value.toString().replace(this.regex, '')
      this.validateValue()
      this.$emit('input', {value: this.data.value.toString().split(',').join(''), isValid: this.data.isValid})
    },
    validateValue () {
      if (this.regex !== null && !this.notValid) {
        this.data.isValid = this.regex.test(this.data.value)
      } else if (this.isEmpty) {
        this.data.isValid = true
      } else if (this.notValid) {
        this.data.isValid = false
      } else {
        this.data.isValid = this.data.value.toString().length > 0
      }
    },
    initValues () {
      this.data.value = this.value.value
      this.validateValue()
    },
    handleKeydownEnter () {
      this.$emit('pressEnter')
    },
  },
  watch: {
    value: {
      handler () {
        this.initValues()
      },
      deep: true
    },
    'data.isValid': {
      handler () {
        this.$emit('input', {value: this.data.value.toString().split(',').join(''), isValid: this.data.isValid})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-validate-input {
  position: relative;
  margin: 1rem 0 2rem;
}
</style>
