// reports

export default {
  title: 'Advanced reports',

  logs: {
    title: 'Logs',

    table: {
      header: {
        rowNumber: 'Row',
        createdAt: 'Created at',
        userName: 'User',
        description: 'Description',
        details: 'Details'
      }
    },

    dialogs: {
      title: 'Log detail',
      withoutDescription: 'No further explanation'
    },

    list: {
      title: 'Logs list'
    }
  },

  list: {
    table: {
      header: {
        rowNumber: 'Row',
        type: 'Report type',
        createdAt: 'Create date',
        expiredAt: 'Expiration date',
        startRange: 'Start range',
        endRange: 'End range',
        creator: 'Applicant name',
        phoneNumber: 'Applicant phone number',
        range: 'Report range',
        status: 'Status',
        actions: 'Actions'
      }
    }
  },

  pastTimeDebtor: {
    table: {
      header: {
        rowNumber: 'Row',
        userName: 'User name',
        phoneNumber: 'Phone number',
        debt: 'Amount of debt'
      }
    }
  },

  usersTotalSales: {
    title: 'Users total Reservation price'
  }
}