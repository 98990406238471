// =============================================================================
// SETTING PAGES LAYOUTS
// =============================================================================

export default {
  path: '/settings',
    component: () => import('../../views/admin/settings/index.vue'),
  children: [
  {
    path: '/',
    name: 'Settings',
    component: () => import('../../views/admin/settings/setting/settingMenu.vue'),
    meta: {
      pageTitle: 'تنظیمات',
      pageTitleI18n: 'setting.title'
      // rule: 'editor'
    }
  },
  {
    path: 'edit',
    name: 'clubInfoSetting',
    component: () => import('../../views/admin/settings/clubInfo/clubInfoSetting.vue'),
    meta: {
      pageTitle: 'تنظیمات',
      pageTitleI18n: 'setting.title'
      // rule: 'editor'
    }
  },
  {
    path: 'access',
    component: () => import('../../views/admin/settings/access/index.vue'),
    children: [
      {
        path: '/',
        name: 'accessGroupsList',
        component: () => import('../../views/admin/settings/access/list/accessGroupsList.vue'),
        meta: {
          permission: 'role.show',
          pageTitle: 'تنظیمات گروه های کاربری',
          pageTitleI18n: 'setting.access.title'
          // rule: 'editor'
        }
      }
      /*{
        path: 'insert',
        name: 'insertAccessGroup',
        component: () => import('../../views/admin/settings/access/insert/insertAccessGroup.vue'),
        meta: {
          pageTitle: 'افزودن گروه کاربری جدید',
          pageTitleI18n: 'setting.access.insert.title',
          // rule: 'editor'
        },
      },
      {
        path: ':id/edit',
        name: 'editAccessGroup',
        component: () => import('../../views/admin/settings/access/edit/editAccessGroup.vue'),
        meta: {
          pageTitle: 'ویرایش گروه کاربری',
          pageTitleI18n: 'setting.access.edit.title',
          // rule: 'editor'
        },
      },*/
    ]
  },
  {
    path: 'buy',
    name: 'buySetting',
    component: () => import('../../views/admin/settings/buy/BuySettings.vue'),
    meta: {
      pageTitle: 'تنظیمات خرید',
      pageTitleI18n: 'setting.Buy.title'
      // rule: 'editor'
    }
  },
  {
    path: 'sale',
    name: 'saleSetting',
    component: () => import('../../views/admin/settings/sale/SaleSettings.vue'),
    meta: {
      pageTitle: 'تنظیمات فروش',
      pageTitleI18n: 'setting.Sale.title'
      // rule: 'editor'
    }
  },
  {
    path: 'languages',
    name: 'languagesSetting',
    component: () => import('../../views/admin/settings/locale/LocaleSetting.vue'),
    meta: {
      pageTitle: 'زبان ها',
      pageTitleI18n: 'setting.Locale.title'
      // rule: 'editor'
    }
  },
  {
    path: 'users',
    name: 'usersSetting',
    component: () => import('../../views/admin/settings/users/usersSetting.vue'),
    meta: {
      pageTitle: 'تنظیمات اشخاص',
      pageTitleI18n: 'setting.users.title'
      // rule: 'editor'
    }
  },
  {
    path: 'security',
    name: 'securitySetting',
    component: () => import('../../views/admin/settings/security/securitySetting.vue'),
    meta: {
      pageTitle: 'امنیت',
      pageTitleI18n: 'setting.security.title'
      // rule: 'editor'
    }
  },
  {
    path: 'faq',
    name: 'faqSetting',
    component: () => import('../../views/admin/settings/faq/list/faqList.vue'),
    meta: {
      pageTitle: 'پرسش متداول',
      pageTitleI18n: 'setting.faq.title'
      // rule: 'editor'
    }
  },
  {
    path: 'rules',
    name: 'rulesSetting',
    component: () => import('../../views/admin/settings/rules/rulesSetting.vue'),
    meta: {
      pageTitle: 'پرسش متداول',
      pageTitleI18n: 'setting.faq.title'
      // rule: 'editor'
    }
  },
  {
    path: 'customizing',
    name: 'customizeSetting',
    component: () => import('../../views/admin/settings/customize/customizeSetting.vue'),
    meta: {
      pageTitle: 'شخصی سازی سامانه',
      pageTitleI18n: 'setting.customize.title'
      // rule: 'editor'
    }
  },
  {
    path: 'treasury',
    name: 'treasurySetting',
    component: () => import('../../views/admin/settings/treasury/TreasurySettings.vue'),
    meta: {
      pageTitle: 'تنظیمات خزانه',
      pageTitleI18n: 'setting.Treasury.title'
      // rule: 'editor'
    }
  },
  {
    path: 'financial-year',
    name: 'financialYearSetting',
    component: () => import('../../views/admin/settings/financialYear/list/financialYearList.vue'),
    meta: {
      permission: 'financial_year.show',
      pageTitle: 'تنظیمات سال مالی',
      pageTitleI18n: 'setting.financialYear.title'
      // rule: 'editor'
    }
  },
  {
    path: 'financial',
    name: 'financialSetting',
    component: () => import('../../views/admin/settings/financial/financialSetting.vue'),
    meta: {
      // permission: 'financial_year.show',
      pageTitle: 'تنظیمات مالی',
      pageTitleI18n: 'setting.financial.title'
      // rule: 'editor'
    }
  },
  {
    path: 'store',
    name: 'storeSetting',
    component: () => import('../../views/admin/settings/store/StoreSettings.vue'),
    meta: {
      pageTitle: 'تنظیمات انبار',
      pageTitleI18n: 'setting.Store.title'
      // rule: 'editor'
    }
  },
  {
    path: 'advanced',
    name: 'advancedSetting',
    component: () => import('../../views/admin/settings/advanced/advancedSetting.vue'),
    meta: {
      pageTitle: 'تنظیمات پیشرفته',
      pageTitleI18n: 'setting.Store.title'
      // rule: 'editor'
    }
  }
]
}