// =============================================================================
// WIZARD PAGES LAYOUTS
// =============================================================================

export default {
  path: 'wizard',
    component: () => import('../../../views/admin/club/index.vue'),
  children: [
  {
    path: '/',
    name: 'clubWizard',
    component: () => import('../../../views/admin/club/wizard/clubWizard.vue'),
    meta: {
      permission: 'time.show',
      pageTitle: 'تایم',
      pageTitleI18n: 'wizard.club.title'
      // rule: 'editor'
    }
  }
]
}