// =============================================================================
// CLUB PAGES LAYOUTS
// =============================================================================

import wizard from './wizard'
import times from './times'
import salesAddons from './salesAddons'
import courts from './courts'
import terms from './terms'
import refunds from './refunds'
import discountGroups from './discountGroups'
import closedInvoices from './closedInvoices'

export default {
  path: '/club',
  component: () => import('../../../views/admin/club/index.vue'),
  children: [
    {
      path: '/',
      redirect: '/error-404'
    },
    wizard,
    times,
    salesAddons,
    courts,
    terms,
    refunds,
    discountGroups,
    closedInvoices
  ]
}