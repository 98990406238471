export default {
  path: 'payment-gateways',
  component: () => import('../../../views/admin/treasury/paymentGateways/index.vue'),
  children: [
    {
      path: '/',
      name: 'paymentGateways',
      component: () => import('../../../views/admin/treasury/paymentGateways/list/paymentGateways.vue'),
      meta: {
        permission: 'payment_gateway.show',
        pageTitle: 'درگاه های پرداخت'
        // rule: 'editor'
      }
    },
    {
      path: 'insert',
      name: 'insertPaymentGateway',
      component: () => import('../../../views/admin/treasury/paymentGateways/insert/insertPaymentGateway.vue'),
      meta: {
        permission: 'payment_gateway.create',
        pageTitle: 'افزودن درگاه پرداخت'
        // rule: 'editor'
      }
    },
    {
      path: 'trash',
      name: 'trashedPaymentGateways',
      component: () => import('../../../views/admin/treasury/paymentGateways/trash/trashedPaymentGateways.vue'),
      meta: {
        permission: ['payment_gateway.show', 'payment_gateway.delete'],
        pageTitle: 'سطل زباله درگاه های پرداخت'
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      component: () => import('../../../views/admin/treasury/paymentGateways/paymentGateway/index.vue'),
      children: [
        {
          path: '/',
          name: 'paymentGateway',
          component: () => import('../../../views/admin/treasury/paymentGateways/paymentGateway/profile/paymentGateway.vue'),
          meta: {
            permission: 'payment_gateway.show',
            pageTitle: 'درگاه پرداخت'
            // rule: 'editor'
          }
        },
        {
          path: 'edit',
          name: 'editPaymentGateway',
          component: () => import('../../../views/admin/treasury/paymentGateways/paymentGateway/edit/editPaymentGateway.vue'),
          meta: {
            permission: 'payment_gateway.update',
            pageTitle: 'ویرایش درگاه پرداخت'
            // rule: 'editor'
          }
        },
        {
          path: 'transactions',
          name: 'transactionsPaymentGateway',
          component: () => import('../../../views/admin/treasury/paymentGateways/paymentGateway/transactions/paymentGatewayTransactions.vue'),
          meta: {
            permission: 'payment_gateway.show',
            pageTitle: 'گزارشات درگاه پرداخت'
            // rule: 'editor'
          }
        },
        {
          path: 'receive',
          name: 'receivePaymentGateway',
          component: () => import(/* webpackChunkName: "receivePaymentGateway" */ '../../../views/admin/treasury/paymentGateways/paymentGateway/receive/receivedPaymentGatewayBalance.vue'),
          meta: {
            permission: 'payment_gateway.show',
            pageTitle: 'اعلام وصول درگاه پرداخت',
            pageTitleI18n: 'treasury.paymentGateways.receive.title'
            // rule: 'editor'
          }
        },
        {
          path: 'activities-log',
          name: 'activitiesLogPaymentGateway',
          component: () => import(/* webpackChunkName: "paymentGatewayActivitiesLog" */ '../../../views/admin/treasury/paymentGateways/paymentGateway/activitiesLog/paymentGatewayActivitiesLog.vue'),
          meta: {
            permission: 'activity_logs.show',
            pageTitle: 'لاگ های درگاه پرداخت',
            pageTitleI18n: 'treasury.paymentGateways.logs.title'
            // rule: 'editor'
          }
        }
      ]
    }
  ]
}