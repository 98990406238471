import store from '@/store/store'
import {getProfile} from '@/http/requests/users/users'
import {checkUserPermissions, deleteCookie, getCookie, hideLoading, showLoading} from '../assets/js/functions'
import {getClubInfo} from '@/http/requests/club/clubInfo'
import {getAvatarUrl} from "@/assets/js/functions";

function validateUser () {
  return getProfile()
}

function getClubInfoSetting () {
  getClubInfo().then((response) => {
    const club = response.data
    const clubInfo = {
      club_name: club.club_name,
      club_logo: club.club_avatar ? getAvatarUrl(club.club_avatar) : '',
      club_currency: club.club_currency || '',
      club_currency_code: club.club_currency_code || 'NUN',
      club_language: club.club_language || 'fa',
      club_locale: club.club_locale || 'IRI',
      club_default_country_code: club.club_default_country_code || ''
    }
    this.$store.dispatch('setting/setClubInfo', clubInfo)
    this.$locale.setLocale(club.club_language)
  })
}

export async function beforeEach (to, from, next) {
  /* in this place check user was logged in or not */

  /* enable loading when route changed */
  showLoading()
  // getClubInfoSetting()

  try {
    store.dispatch('helper/changePrevRoute', from)
    store.dispatch('setPageTitle', '')
    let originDomain = window.location.origin

    const originAccessToken = getCookie(`accessToken.${originDomain}`)
    if (originAccessToken) {
      let domain = window.location.hostname.split('.').reverse()
      if (domain.length > 2) domain = domain.splice(0, 2)
      domain = domain.reverse().join('.')
      localStorage.setItem('accessToken', originAccessToken)
      deleteCookie(`accessToken.${originDomain}`, domain)
    }
    const accessToken = localStorage.getItem('accessToken') || getCookie('accessToken')
    if (accessToken) {
      await store.dispatch('auth/setAccessToken').then(() => {
        if (to.name === 'page-login') {
          next({name: 'home'})
        }
        if (from.name === null || from.name === 'page-login') {
          validateUser().then((validate) => {
            if (validate.status === 200) {
              store.dispatch('auth/updateUserInfo', validate.data.data)

              const userInfo = store.state.auth.user
              if (userInfo.active_sessions > userInfo.role.allowed_active_sessions && to.name !== 'Profile' && to.name !== 'profileActiveSessions') {
                next({name: store.getters.isMobile ? 'profileActiveSessions' : 'Profile'})
                hideLoading()
              }

              let tableSettings = JSON.parse(validate.data.data.browser_setting)
              // delete tableSettings.tables.payload
              if (tableSettings) {
                store.dispatch('table/setUserBrowserSettings', tableSettings)
              }

              if (to.meta.hasOwnProperty('permission')) {
                if (checkUserPermissions(to.meta.permission)) {
                  /*if (store.state.pageHaveChanges) {
                    const answer =
                      window.confirm('شما تغییراتی دارید که ذخیره نشده است, آیا مطمئنید که می خواهید خارج شوید؟');

                    if (answer) {
                      store.dispatch('removePageChanges')
                      next();
                    } else {
                      next(false);
                    }
                  } else {
                    next()
                  }*/
                  next()
                } else {
                  next({name: 'page-error-404'})
                }
              } else {
                next()
              }
            } else {
              throw 'auth-error'
            }
          })
        } else {
          const userInfo = store.state.auth.user
          if (userInfo.active_sessions > userInfo.role.allowed_active_sessions && to.name !== 'Profile' && to.name !== 'profileActiveSessions') {
            next({name: store.getters.isMobile ? 'profileActiveSessions' : 'Profile'})
            hideLoading()
          }

          if (to.meta.hasOwnProperty('permission')) {
            if (checkUserPermissions(to.meta.permission)) {
              next()
            } else {
              next({name: 'page-error-404'})
            }
          } else {
            next()
          }
        }
      })
    } else if (!to.matched.some(record => record.name === 'page-login')) {
      throw 'auth-error'
    } else {
      next()
    }
  } catch (e) {
    if (!to.matched.some(record => record.name === 'page-login')) {
      next({
        name: 'page-login'
        /* query: {redirectFrom: to.fullPath}*/
      })
      // next(false)
    } else {
      next()
    }
  }
}
