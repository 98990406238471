// companies

export default {
  title: 'لیست شرکت ها',

  table: {
    header: {
      row: 'ردیف',
      code: 'کد',
      name: 'نام شرکت',
      equity: 'سرمایه',
      totalDebt: 'بدهی کل',
      totalAsset: 'دارایی کل'
    }
  },

  labels: {
    generalInfo: 'اطلاعات عمومی شرکت',
    telInfo: 'اطلاعات تماس شرکت',
    financialInfo: 'اطلاعات مالی',
    uploadLogo: 'آپلود لوگو',
    preview: 'پیشنمایش:',
    code: 'کد شرکت',
    name: 'نام شرکت',
    managerName: 'نام و نام خانوادگی مدیر',
    address: 'آدرس',
    phoneNumber: 'شماره تماس',
    managerPhoneNumber: 'شماره تماس مدیر',
    financialFrom: 'شروع سال مالی',
    financialTo: 'انتهای سال مالی'
  },

  description: {
    logoRule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم لوگو 800 کیلوبایت',
    generalInfo: 'در این باکس اطلاعات عمومی شرکت نمایش داده می شود.',
    telInfo: 'در این باکس اطلاعات تماس شرکت نمایش داده می شود.',
    financialInfo: 'در این باکس اطلاعات مالی شرکت نمایش داده می شود.'
  },

  buttons: {
    save: 'ذخیره تغییرات',
    reset: 'برگرداندن تغییرات',
    removeLogo: 'حذف لوگو',
    uploadLogo: 'بارگزاری لوگو'
  },

  validators: {
    imageSize: 'حجم فایل انتخاب شده بیش از حد مجاز است',
    fileFormat: 'فرمت فایل انتخاب شده مجاز نیست'
  },

  notifications: {
    logo: {
      upload: {
        success: 'لوگو با موفقیت آپلود شد'
      },
      delete: {
        success: 'لوگو با موفقیت حذف شد'
      }
    },

    insert: {
      success: 'اطلاعات فروشگاه با موفقیت اضافه شد'
    },

    edit: {
      success: 'اطلاعات فروشگاه با موفقیت بروز شد'
    }
  },

  company: {
    title: 'باشگاه'
  },

  insert: {
    title: 'افزودن باشگاه'
  },

  edit: {
    title: 'ویرایش اطلاعات باشگاه'
  }
}