export default {
  path: 'cost',
  component: () => import('../../../views/admin/treasury/cost/index.vue'),
  children: [
    {
      path: '',
      name: 'treasuryCost',
      component: () => import('../../../views/admin/treasury/cost/list/costsList.vue'),
      meta: {
        permission: 'cost.show',
        pageTitle: 'هزینه',
        pageTitleI18n: 'treasury.cost.title'
        // rule: 'editor'
      }
    },
    {
      path: 'insert',
      name: 'insertTreasuryCost',
      component: () => import('../../../views/admin/treasury/cost/insert/insertCost.vue'),
      meta: {
        permission: 'cost.create',
        pageTitle: 'هزینه جدید',
        pageTitleI18n: 'treasury.cost.insert.title'
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      name: 'treasuryCostDocument',
      component: () => import('../../../views/admin/treasury/cost/document/costDocument.vue'),
      meta: {
        permission: 'cost.show',
        pageTitle: 'سند هزینه',
        pageTitleI18n: 'treasury.cost.title'
        // rule: 'editor'
      }
    },
    {
      path: 'doc-:id/activities-log',
      name: 'costActivitiesLog',
      component: () => import(/* webpackChunkName: "costActivitiesLog" */ '../../../views/admin/treasury/cost/activitiesLog/costActivitiesLog.vue'),
      meta: {
        permission: 'activity_logs.show',
        pageTitle: 'لاگ های هزینه',
        pageTitleI18n: 'treasury.cost.logs.title'
        // rule: 'editor'
      }
    },
  ]
}