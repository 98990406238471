// =============================================================================
// BACKUP PAGES LAYOUTS
// =============================================================================

export default {
  path: 'backup',
  name: 'backup',
  component: () => import('../../views/admin/backup/backup.vue'),
  meta: {
    permission: 'backup.show',
    pageTitle: 'پشتیبان گیری',
    pageTitleI18n: 'backup.title'
    // rule: 'editor'
  }
}