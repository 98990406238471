// tickets

export default {
  title: 'تیکت ها',

  support: {
    title: 'تیکت پشتیبانی',

    statusTypes: {
      all: 'همه',
      created: 'ایجاد شده',
      customerAnswer: 'پاسخ مشتری',
      operatorAnswer: 'پاسخ اپراتور',
      pending: 'در انتظار بررسی',
      completed: 'بسته شده',
      canceled: 'کنسل شده'
    },

    sendStatusTypes: {
      send: 'ارسال شده',
      notSend: 'ارسال نشده'
    },

    seenStatusTypes: {
      seen: 'مشاهده شده',
      notSeen: 'مشاهده نشده'
    },

    labels: {
      all: 'همه',
      selectType: 'موضوع رو انتخاب کنید',
      service: 'سرویس',
      subject: 'موضوع تیکت',
      message: 'پیام',
      dynamicAttach: 'پیوست {number}',
      insertAttach: 'پیوست جدید',
      unknown: 'نامشخص',
      operator: 'اپراتور'
    },

    placeholder: {
      subject: 'موضوع تیکت خود را وارد کنید',
      message: 'متن پیام خود را وارد کنید'
    },

    table: {
      header: {
        row: 'ردیف',
        department: 'دپارتمان',
        service: 'نام سامانه',
        subject: 'موضوع',
        date: 'تاریخ ایجاد',
        lastUpdate: 'آخرین به روز رسانی',
        operatorName: 'اپراتور',
        creator: 'ایجاد کننده',
        status: 'وضعیت',
        sendStatus: 'وضعیت ارسال',
        seen: 'مشاهده شده'
      }
    },

    notifications: {
      attachUpload: 'فایل مورد نظر آپلود شد',
      requestSend: 'درخواست شما ارسال شد لطفا منتظر بمانید!',

      insert: {
        success: 'تیکت مورد نظر با موفقیت ایجاد شد',
        error: 'افزودن تیکت با خطا همراه شد'
      },

      sendMessage: {
        success: 'پیام مورد نظر با موفقیت ارسال شد',
        error: 'ارسال پیام مورد نظر با خطا همراه شد'
      },

      changeOperator: {
        success: 'ویرایش اپراتور تیکت با موفقیت انجام شد',
        error: 'ویرایش اپراتور تیکت با خطا همراه شد'
      },

      parseError: {
        service: 'سرویس انتخاب شده معتبر نیست!',
        subject: 'موضوع انتخاب شده معتبر نیست!',
        message: 'متن وارد شده معتبر نیست!',
        attachments: 'پیوست انتخاب شده معتبر نیست!',
        operator: 'اپراتور انتخاب شده معتبر نیست!'
      }
    },

    confirmations: {
      send: {
        title: 'تاییدیه ارسال پیام',
        body: 'آیا از ارسال پیام خود اطمینان دارید؟'
      }
    },

    validators: {
      subject: 'موضوع تیکت به صورت صحیح وارد نشده است!',
      message: 'متن پیام به صورت صحیح وارد نشده است!',
      service: 'سرویس به صورت صحیح انتخاب نشده است!'
    },

    list: {
      title: 'تیکت های پشتیبانی'
    },

    insert: {
      title: 'افزودن تیکت پشتیبانی جدید'
    },

    answer: {
      title: 'افزودن پاسخ به تیکت پشتیبانی'
    },

    detail: {
      title: 'جزئیات تیکت پشتیبانی',

      labels: {
        ticketId: 'تیکت ',
        status: 'وضعیت تیکت: ',
        createdAt: 'زمان ایجاد: ',
        lastUpdate: 'آخرین بروزرسانی: ',
        user: 'کاربر',
        operator: 'اپراتور'
      }
    }
  },

  organization: {
    title: 'تیکت درون سازمانی',

    statusTypes: {
      all: 'همه',
      created: 'ایجاد شده',
      creatorAnswer: 'پاسخ ایجاد کننده',
      receiverAnswer: 'پاسخ مسئول پیگیری',
      pending: 'در انتظار بررسی',
      completed: 'بسته شده',
      canceled: 'کنسل شده'
    },

    sendStatusTypes: {
      send: 'ارسال شده',
      notSend: 'ارسال نشده'
    },

    seenStatusTypes: {
      seen: 'مشاهده شده',
      notSeen: 'مشاهده نشده'
    },

    labels: {
      all: 'همه',
      selectType: 'موضوع رو انتخاب کنید',
      user: 'گیرنده',
      subject: 'موضوع تیکت',
      message: 'پیام',
      dynamicAttach: 'پیوست {number}',
      insertAttach: 'پیوست جدید',
      unknown: 'نامشخص',
      operator: 'اپراتور'
    },

    placeholder: {
      subject: 'موضوع تیکت خود را وارد کنید',
      message: 'متن پیام خود را وارد کنید'
    },

    table: {
      header: {
        row: 'ردیف',
        creator: 'ایجاد کننده',
        user: 'مسئول پیگیری',
        subject: 'موضوع',
        date: 'تاریخ ایجاد',
        lastUpdate: 'آخرین به روز رسانی',
        status: 'وضعیت',
      }
    },

    notifications: {
      attachUpload: 'فایل مورد نظر آپلود شد',
      requestSend: 'درخواست شما ارسال شد لطفا منتظر بمانید!',

      insert: {
        success: 'تیکت مورد نظر با موفقیت ایجاد شد',
        error: 'افزودن تیکت با خطا همراه شد'
      },

      sendMessage: {
        success: 'پیام مورد نظر با موفقیت ارسال شد',
        error: 'ارسال پیام مورد نظر با خطا همراه شد'
      },

      changeOperator: {
        success: 'ویرایش اپراتور تیکت با موفقیت انجام شد',
        error: 'ویرایش اپراتور تیکت با خطا همراه شد'
      },

      parseError: {
        user: 'گیرنده انتخاب شده معتبر نیست!',
        service: 'سرویس انتخاب شده معتبر نیست!',
        subject: 'موضوع انتخاب شده معتبر نیست!',
        message: 'متن وارد شده معتبر نیست!',
        attachments: 'پیوست انتخاب شده معتبر نیست!',
        operator: 'اپراتور انتخاب شده معتبر نیست!'
      }
    },

    confirmations: {
      send: {
        title: 'تاییدیه ارسال پیام',
        body: 'آیا از ارسال پیام خود اطمینان دارید؟'
      }
    },

    validators: {
      user: 'گیرنده تیکت به صورت صحیح وارد نشده است!',
      subject: 'موضوع تیکت به صورت صحیح وارد نشده است!',
      message: 'متن پیام به صورت صحیح وارد نشده است!',
      service: 'سرویس به صورت صحیح انتخاب نشده است!'
    },

    list: {
      title: 'تیکت های درون سازمانی'
    },

    insert: {
      title: 'افزودن تیکت درون سازمانی جدید'
    },

    answer: {
      title: 'افزودن پاسخ به تیکت درون سازمانی'
    },

    detail: {
      title: 'جزئیات تیکت درون سازمانی',

      labels: {
        ticketId: 'تیکت ',
        status: 'وضعیت تیکت: ',
        createdAt: 'زمان ایجاد: ',
        lastUpdate: 'آخرین بروزرسانی: ',
        user: 'کاربر',
        operator: 'اپراتور'
      }
    }
  },
}