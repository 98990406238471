/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

const actions = {

  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth ({ commit }, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
  },

  // Content Navbar
  updateContentNavbarStyle ({ commit }, style) {
    commit('UPDATE_CONTENT_NAVBAR_STYLE', style)
  },
  updateContentNavbarClass ({ commit }, className) {
    commit('UPDATE_CONTENT_NAVBAR_CLASS', className)
  },

  // VxAutoSuggest
  updateStarredPage ({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload)
  },

  // The Navbar
  arrangeStarredPagesLimited ({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  },
  arrangeStarredPagesMore ({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list)
  },
  updateNavbarActions ({ commit }, actions) {
    commit('UPDATE_NAVBAR_ACTIONS', actions)
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay ({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY')
  },
  updateTheme ({ commit }, val) {
    commit('UPDATE_THEME', val)
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateUserInfo ({ commit }) {
    commit('UPDATE_APP_ACTIVE_USER')
  },

  // /////////////////////////////////////////////
  // OTHER
  // /////////////////////////////////////////////

  setPageTitle ({ commit }, title) {
    commit('SET_PAGE_TITLE', title)
  },

  setPageChanges ({ commit }) {
    commit('SET_PAGE_CHANGES')
  },
  removePageChanges ({ commit }) {
    commit('REMOVE_PAGE_CHANGES')
  },
  changeFilterRange ({ commit }, type) {
    commit('CHANGE_FILTER_RANGE', type)
  },

  setLocale ({ commit }, payload) {
    commit('SET_LOCALE', payload)
  }
}

export default actions
