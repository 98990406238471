export default {
  path: 'payment',
  component: () => import('../../../views/admin/treasury/payment/index.vue'),
  children: [
    {
      path: '',
      name: 'treasuryPayment',
      component: () => import('../../../views/admin/treasury/payment/list/paymentsList.vue'),
      meta: {
        pageTitle: 'پرداخت',
        pageTitleI18n: 'treasury.payment.title',
        permission: 'payment.show'
        // rule: 'editor'
      }
    },
    {
      path: 'insert',
      name: 'insertTreasuryPayment',
      component: () => import('../../../views/admin/treasury/payment/insert/insertPayment.vue'),
      meta: {
        pageTitle: 'پرداخت جدید',
        pageTitleI18n: 'treasury.payment.insert.title',
        permission: 'payment.create'
        // rule: 'editor'
      }
    },
    {
      path: ':id',
      name: 'treasuryPaymentDocument',
      component: () => import('../../../views/admin/treasury/payment/document/paymentDocument.vue'),
      meta: {
        pageTitle: 'سند پرداخت',
        pageTitleI18n: 'treasury.payment.title',
        permission: 'payment.show'
        // rule: 'editor'
      }
    },
    {
      path: 'doc-:id/activities-log',
      name: 'paymentActivitiesLog',
      component: () => import(/* webpackChunkName: "paymentActivitiesLog" */ '../../../views/admin/treasury/payment/activitiesLog/paymentActivitiesLog.vue'),
      meta: {
        permission: 'activity_logs.show',
        pageTitle: 'لاگ های پرداخت',
        pageTitleI18n: 'treasury.payment.logs.title'
        // rule: 'editor'
      }
    }
  ]
}