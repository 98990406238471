// =============================================================================
// ACCOUNTANCY PAGES LAYOUTS
// =============================================================================

export default {
  path: '/accountancy',
  component: () => import('../../views/admin/accountancy/index.vue'),
  children: [
    {
      path: 'types',
      name: 'documentsType',
      component: () => import('../../views/admin/accountancy/types/documentsType.vue'),
      meta: {
        permission: 'account.access',
        pageTitle: 'جدول حساب ها',
        pageTitleI18n: 'accountancy.types.title'
        // rule: 'editor'
      }
    },
    {
      path: 'documents',
      component: () => import('../../views/admin/accountancy/documents/index.vue'),
      children: [
        {
          path: '',
          name: 'documents',
          component: () => import('../../views/admin/accountancy/documents/list/documentsList.vue'),
          meta: {
            permission: 'document.access',
            pageTitle: 'لیست اسناد',
            pageTitleI18n: 'accountancy.documents.title'
            // rule: 'editor'
          }
        },
        {
          path: 'insert',
          name: 'insertDocument',
          component: () => import('../../views/admin/accountancy/documents/insert/insertDocument.vue'),
          meta: {
            permission: 'document.access',
            pageTitle: 'افزودن سند دریافت/پرداخت',
            pageTitleI18n: ''
            // rule: 'editor'
          }
        },
        {
          path: 'doc-:id',
          name: 'document',
          component: () => import('../../views/admin/accountancy/documents/document/document.vue'),
          meta: {
            permission: 'document.access',
            pageTitle: 'سند',
            pageTitleI18n: 'accountancy.documents.document.title'
            // rule: 'editor'
          }
        }
      ]
    },
    {
      path: 'opening-balance',
      name: 'openingBalance',
      component: () => import('../../views/admin/accountancy/openingBalance/index.vue'),
      meta: {
        permission: 'opening.access',
        pageTitle: 'تراز افتتاحیه',
        pageTitleI18n: 'accountancy.openingBalance.title'
        // rule: 'editor'
      }
    }
  ]
}