// reports

export default {
  title: 'گزارشات پیشرفته',

  logs: {
    title: 'لاگ ها',

    table: {
      header: {
        rowNumber: 'ردیف',
        createdAt: 'تاریخ لاگ',
        userName: 'کاربر',
        description: 'توضیحات',
        details: 'جزییات'
      }
    },

    dialogs: {
      title: 'جزییات لاگ',
      withoutDescription: 'بدون توضیحات بیشتر'
    },

    list: {
      title: 'لیست لاگ ها'
    }
  },

  list: {
    table: {
      header: {
        rowNumber: 'ردیف',
        type: 'نوع گزارش',
        createdAt: 'تاریخ ایجاد',
        expiredAt: 'تاریخ انقضاء',
        startRange: 'شروع بازه',
        endRange: 'پایان بازه',
        creator: 'نام درخواست دهنده',
        phoneNumber: 'شماره درخواست دهنده',
        range: 'بازه گزارش',
        status: 'وضعیت',
        actions: 'اکشن ها'
      }
    }
  },

  pastTimeDebtor: {
    table: {
      header: {
        rowNumber: 'ردیف',
        userName: 'نام شخص',
        phoneNumber: 'شماره تلفن',
        debt: 'میزان بدهی'
      }
    }
  },

  usersTotalSales: {
    title: 'مجموع فروش به اشخاص'
  }
}