// sales

export default {
  title: 'Booking',

  addons: {
    title: 'Additional services',

    labels: {
      all: 'All',
      until: ' to ',
      required: 'Required',
      price: 'Price',
      isRequired: 'Required',
      notRequired: 'Not required',
      name: 'Name',
      maxSelect: 'Maximum number of selections (unlimited if empty)',
      priceBaseCharacter: 'Pricing based on customer group',
      delete: 'Delete additional service'
    },

    invalidTexts: {
      name: 'The name must be English!'
    },

    notifications: {
      addonIsUsed: 'Additional service information used somewhere!',

      insert: {
        success: 'Additional service information was successfully registered',
        error: 'Insert additional service has error'
      },

      edit: {
        success: 'Additional service information was successfully updated',
        error: 'Edit additional service has error'
      },

      delete: {
        success: 'Additional service information was successfully deleted',
        error: 'Delete additional service has error',
        isUsedError: 'The additional service cannot be deleted due to the history of the documents!'
      },

      restore: {
        success: 'Additional service information was successfully restored',
        error: 'Restore additional service has error'
      },

      parseError: {
        name: 'The selected name is invalid!',
        price: 'The selected price is invalid!',
        character: 'The selected customer group is invalid!',
        maxSelect: 'The maximum number of selections is not valid!'
      }
    },

    validators: {
      name: 'The selected name is invalid',
      maxSelect: 'The maximum number of selections is not valid',
      noCharacter: 'Customer group not selected',
      price: 'The price of the {name} group is not entered correctly',
      noAddonExist: 'There are no additional services for this user'
    },

    confirmations: {
      delete: {
        title: 'Delete additional service confirmation',
        body: 'Are you sure you want to delete the {name} temporarily?'
      },

      forceDelete: {
        title: 'Delete additional service confirmation',
        body: 'Are you sure you want to delete the {name} permanently?'
      }
    },

    list: {
      title: 'Additional services list',

      table: {
        header: {
          row: 'Row',
          name: 'Name',
          character: 'Customer group',
          price: 'Price',
          delete: 'Delete',
          restore: 'Restore'
        }
      }
    },

    insert: {
      title: 'Insert new additional service',
    },

    edit: {
      title: 'Edit additional service',
      dynamicTitle: 'Edit {name} information'
    },

    profile: {
      title: 'Additional service profile',
      dynamicTitle: '{name} income account',

      table: {
        header: {
          name: 'name',
          required: 'required',
          price: 'Price',
        }
      }
    },

    logs: {
      title: 'Additional service logs',
    },

    trash: {
      title: 'Removed additional service'
    }
  },

  discount: {
    title: 'Discounts',

    labels: {
      discount: 'Discount',
      percent: '%'
    },

    table: {
      header: {
        name: 'Group name'
      }
    },

    notifications: {
      wrongValue: 'The value entered is invalid',

      edit: {
        success: 'Discounts were successfully edited',
        error: 'Editing discounts has error!'
      }
    },

    edit: {
      title: 'Edit discounts',
      dynamicModalTitle: 'Discount for {character} in {term} time category'
    }
  },

  invoices: {
    title: 'Bookings list',

    labels: {
      all: 'All',
      id: 'Invoice id',
      user: 'User',
      status: 'Status',
      actionType: 'Reservation method',
      date: 'Created date',
      userInfo: 'User info',
      reserveInfo: 'Reserve info',
      addonInfo: 'Additional services',
      totalPrice: 'Total price',
      totalDiscount: 'Total discount',
      discount: 'Discount',
      reject: 'Refund',
      finalPrice: 'Final price',
      until: ' to ',
      active: 'Active',
      passed: 'Past',
      reserved: 'Reserved',
      canceled: 'Canceled',
      fromNow: 'From today on',
      gender: 'Gender: ',
      fullName: 'Name and last name: ',
      phoneNumber: 'Phone number: ',
      courtName: 'Court name: ',
      reserveTime: 'Reserve time: ',
      price: 'Price: ',
      required: '(Required)',
      lastWeek: 'Last week',
      futureWeek: 'Next week',
      timeCourt: 'Time / Court',
      timeWeek: 'Time / Week',
      confirm: 'Booking confirmation list',
      selectTime: 'Select time',
      coach: 'Coach',
      coachInfo: 'Coach info',
      coachPrice: 'Coach\'s fee'
    },

    placeholder: {
      id: 'Automatic'
    },

    notifications: {
      coachCantSelect: 'The selected coach cannot be booked at times marked in red',
      coachCantSelectEdit: 'The selected coach cannot be booked at the desired time',
      lowMoney: 'Your balance is not enough to book!',
      lowMoneyForAddon: 'Your balance is not enough to book an additional services!',
      noTimeExist: 'Some selected times is not available!',
      timesReserved: 'A number of selected times have already been booked!',
      timesCanceled: 'A number of selected times have been canceled!',
      addonCanceled: 'The extra option has been canceled!',
      cantDeleteAddon: 'Required additional services cannot be removed!',
      cantDeleteCourt: 'Reservation court cannot be removed!',
      addonExist: 'The {name} has already been selected!',
      maxAddon: 'The maximum number of {name} can be {count}.',
      discountIsBigger: '{name} discount is more than the total price!',
      cantDelete: 'This invoice cannot be deleted!',
      userGroup: 'No group is set for {name}!',
      discountsError: 'Receiving user discounts has error!',

      insert: {
        success: 'Invoice registered successfully',
        error: 'Invoice registration has error!'
      },

      edit: {
        success: 'Invoice information was successfully updated',
        error: 'Invoice editing has error!'
      },

      delete: {
        success: 'Invoice removed successfully',
        error: 'Deleting the invoice has error'
      },

      complete: {
        success: 'Invoice registered successfully',
        error: 'Invoice registration has error!'
      },

      insertAddon: {
        success: 'Additional services added successfully!',
        error: 'Adding additional services has error!'
      },

      cancel: {
        success: 'Invoice was successfully restored',
        error: 'The invoice restore request failed!'
      },

      cancelSaleAddon: {
        success: 'Additional services successfully canceled!',
        error: 'The extra option removal request failed!'
      },

      parseError: {
        date: 'The selected date is not valid!'
      }
    },

    validators: {
      user: 'User not selected!',
      noTime: 'No time has been set for booking!',
      emptyTime: 'Deleting this order will empty your booking list!',
    },

    confirmations: {
      forceDelete: {
        title: 'Invoice Delete confirmation',
        body: 'Are you sure you want to delete the {name} permanently?'
      }
    },

    statusTypes: {
      all: 'All',
      active: 'Active',
      activePassed: 'Used',
      canceled: 'Canceled',
      canceledBySystem: 'Canceled by system',
      confirmed: 'Final approved'
    },

    processTypes: {
      preInvoice: 'Pre-invoice booking',
      invoice: 'Invoice booking'
    },

    financialStatusTypes: {
      debtor: 'Debtor',
      checkout: 'Settlement'
    },

    actionTypes: {
      present: 'In-person reservation',
      shortPresent: 'In-person ',
      tel: 'Phone reservation',
      shortTel: 'Phone ',
      internet: 'Online booking',
      shortInternet: 'Online'
    },

    calendar: {
      title: 'Calendar'
    },

    list: {
      title: 'Bookings list',

      table: {
        header: {
          docNumber: 'Number',
          creator: 'Creator',
          registryDate: 'Create date',
          user: 'User',
          reserveDate: 'Booking date',
          time: 'Booking time',
          courtName: 'Court name',
          finalPrice: 'Final price',
          totalPrice: 'Total price',
          status: 'Status',
          coach: 'Coach',
          delete: 'Delete',
          restore: 'Restore'
        }
      },

      sortTypes: {
        newest: 'جدیدترین',
        finalPrice: 'گران ترین'
      }
    },

    insert: {
      title: 'Insert booking',

      past: {
        title: 'Add a booking invoice for the past'
      },

      table: {
        header: {
          row: 'row',
          name: 'Booking',
          reservePrice: 'Booking price',
          saleAddons: 'Additional services',
          coachPrice: 'Coach\'s fee',
          totalPrice: 'Total price',
          discount: 'Total discount',
          finalPrice: 'Final price',
          delete: 'Delete',
        }
      }
    },

    invoice: {
      title: 'Reserve invoice',
      dynamicTitle: '{type} reserve invoice',
      dynamicTitleWithId: '{type} reserve invoice number {id}',

      labels: {
        cancelReserve: 'Cancel booking',
        cancelAddon: 'Cancel additional services'
      },

      table: {
        header: {
          row: 'row',
          name: 'name',
          quantity: 'Quantity',
          unitPrice: 'Unit price',
          finalPrice: 'Total price'
        }
      },

      cancel: {
        title: 'Cancellation method',

        byCustomer: 'By customer',
        bySystem: 'By system',

        labels: {
          timeUntilReserve: 'Less than {time} hours left until the start of time',
          timeRefund: 'time refund expense: ',
          coachRefund: 'coach refund expense: ',
          addonRefund: 'Additional services refund expense:',
          totalRefundPrice: 'totally: '
        }
      },

      confirmations: {
        delete: {
          title: 'Delete invoice confirmation',
          body: 'If the booking invoice is deleted, all documents related to the invoice will be deleted. Are you sure?'
        },

        cancelReserve: {
          title: 'Cancel invoice confirmation',
          body: 'Are you sure you want to cancel your booking?'
        },

        cancelAddon: {
          title: 'Delete additional services confirmation',
          body: 'Are you sure you want to cancel your additional services?'
        }
      },

      logs: {
        title: 'Sale invoice Logs',
      }
    },

    edit: {
      title: 'Edit booking invoice',
      dynamicTitle: 'Edit {type} booking invoice',
    },

    trash: {
      title: 'Removed reservation list'
    },

    receive: {
      title: 'Insert booking invoice',
      dynamicTitle: 'Insert booking invoice number {id}',

      labels: {
        payment: 'Pay',
        receiveAndReserve: 'Receive and Book',
        cancel: 'Cancel'
      }
    }
  },

  seasonSchedules: {
    labels: {
      id: 'Invoice id',
      user: 'User',
      timeWeek: 'Time / Week',
      confirm: 'Booking confirmation list',
      selectDateRange: 'Select time range',
      startDate: 'Start date',
      endDate: 'End date',
      quantity: 'Number of times',
      until: ' to '
    },

    notifications: {
      lowMoney: 'Your balance is not enough to book!',
      timesReserved: 'A number of selected times have already been booked!',
      seasonDateRange: 'The start and end date of the seasonal reservation is not valid!',
      startDate: 'The start date entered is invalid!',
      endDate: 'The end date entered is invalid!',
      requestSend: 'Your request has been sent, please wait!',
      cantDelete: 'This invoice cannot be deleted!',
      mustGTEWeek: 'Date range should be more than a week!',

      insert: {
        success: 'Seasonal booking invoice successfully registered',
        error: 'Seasonal booking invoice registration has error!'
      },

      delete: {
        success: 'Invoice removed successfully',
        error: 'Deleting the seasonal booking invoice has error'
      },


      parseError: {
        date: 'The selected date is not valid!',
        getTimes: 'Receiving times has error',
        startDate: 'Entered start date is invalid, start date must be from tomorrow onwards!',
        endDate: 'The end date entered is invalid!'
      }
    },

    validators: {
      user: 'User is not valid!',
      noTime: 'No time has been set for booking!',
      emptyTime: 'Deleting this order will empty your booking list!',
    },

    confirmations: {
      delete: {
        title: 'Delete seasonal booking invoice confirmation',
        body: 'If the seasonal booking invoice is deleted, all documents related to the invoice will be deleted. Are you sure?'
      },
    },

    insert: {
      title: 'Add seasonal booking',
      dynamicTitle: 'Add seasonal booking for {gender} {name}',

      labels: {
        user: 'User',
        totalPrice: 'Total price',
        finalPrice: 'Final price',
        totalDiscount: 'Total discount',
        totalQuantity: 'Total quantity'
      },

      table: {
        header: {
          row: 'Row',
          court: 'Court',
          quantity: 'Quantity',
          totalPrice: 'Total price',
          discount: 'Discount',
          finalPrice: 'Final price',
          delete: 'Delete'
        }
      },
    },

    invoice: {
      title: 'Seasonal booking invoice'
    },

    disableTimes: {
      title: 'A list of available invoices was selected for Timeه',

      labels: {
        dynamicSaleInvoice: 'Reservation number {id}',
        dynamicClosedInvoice: 'Vacation announcement number {id}'
      },

      table: {
        header: {
          row: 'Row',
          user: 'User',
          description: 'Description',
          date: 'Date'
        }
      }
    },
    receive: {
      title: 'Add seasonal booking',
      dynamicTitle: 'Add seasonal booking number {id}',

      labels: {
        payment: 'Pay',
        receiveAndReserve: 'Receive and book',
        cancel: 'Cancel'
      }
    }
  },

  detail: {
    title: 'Sales reports'
  },

  actions: {
    insert: 'Insert',
    edit: 'Edit',
    filters: 'Filters'
  }
}