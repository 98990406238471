// courts

export default {
  title: 'زمین ها',

  labels: {
    image: 'تصویر موقعیت مکانی زمین در باشگاه',
    name: 'نام',
    type: 'نوع زمین',
    delete: 'حذف زمین',
  },

  types: {
    all: 'همه',
    hard: 'هارد',
    ground: 'خاک',
    grass: 'چمن'
  },

  roofTypes: {
    always: 'دائم',
    season: 'فصلی',
    without: 'سرباز'
  },

  notifications: {
    insert: {
      success: 'اطلاعات زمین با موفقیت ثبت شد',
      error: 'ثبت اطلاعات زمین با خطا مواجه شد'
    },

    edit: {
      success: 'اطلاعات زمین با موفقیت بروزرسانی شد',
      error: 'ویرایش اطلاعات زمین با خطا مواجه شد'
    },

    delete: {
      success: 'اطلاعات زمین مورد نظر حذف شد',
      error: 'حذف اطلاعات زمین با خطا همراه شد',
      isUsedError: 'زمین مورد نظر به دلیل داشتن سابقه اسناد، قابل حذف نمی باشد!'
    },

    restore: {
      success: 'زمین با موفقیت بازگردانده شد',
      error: 'بازگردانی زمین با خطا مواجه شد'
    },

    parseError: {
      name: 'نام انتخاب شده معتبر نیست!',
      type: 'نوع زمین معتبر نیست!',
      image: 'تصویر انتخاب شده معتبر نیست!'
    }
  },

  validators: {
    name: 'نام وارد شده صحیح نمی باشد',
    type: 'نوع زمین انتخاب شده صحیح نمی باشد'
  },

  confirmations: {
    delete: {
      title: 'تاییدیه حذف زمین',
      body: 'آیا از حذف موقت {name} اطمینان دارید؟'
    },

    forceDelete: {
      title: 'تاییدیه حذف زمین',
      body: 'آیا از حذف دائم {name} اطمینان دارید؟'
    }
  },

  list: {
    title: 'لیست زمین ها',

    table: {
      header: {
        row: 'ردیف',
        image: 'موقعیت زمین',
        name: 'نام زمین',
        type: 'نوع زمین',
        createdAt: 'تاریخ ایجاد',
        delete: 'حذف',
        restore: 'بازگردانی'
      }
    }
  },

  insert: {
    title: 'افزودن زمین جدید'
  },

  edit: {
    title: 'ویرایش زمین',
    dynamicTitle: 'ویرایش اطلاعات {name}'
  },

  profile: {
    title: 'پروفایل زمین',
    dynamicTitle: 'پروفایل {name}',

    actions: {
      edit: 'ویرایش',
      price: 'قیمت گذاری زمین',
      logs: 'لیست لاگ ها'
    },

    asiderAvatar: {
      type: 'نوع زمین: '
    }
  },

  trash: {
    title: 'سطل زباله زمین'
  },

  price: {
    table: {
      header: {
        termName: 'نام سانس',
        saturday: 'شنبه',
        sunday: 'یک شنبه',
        monday: 'دوشنبه',
        tuesday: 'سه شنبه',
        wednesday: 'چهارشنبه',
        thursday: 'پنج شنبه',
        friday: 'جمعه'
      }
    },

    notifications: {
      edit: {
        success: 'ویرایش لیست قیمت ها با موفقیت انجام شد',
        error: 'ویرایش لیست قیمت ها با خطا مواجه شد'
      }
    },

    list: {
      title: 'لیست قیمت زمین',
      dynamicTitle: 'قیمت گذاری {name}'
    },

    edit: {
      title: 'ویرایش لیست قیمت زمین',
      dynamicTitle: 'ویرایش لیست قیت {name}'
    }
  },

  logs: {
    title: 'لاگ های زمین'
  }
}