// =============================================================================
// TREASURY PAGES LAYOUTS
// =============================================================================

import banks from './banks'
import paymentGateways from './paymentGateways'
import cashBoxes from './cashBoxes'
import receive from './receive'
import cost from './cost'
import payment from './payment'
import internalFundsTransfer from './internalFundsTransfer'
import transactions from './transactions'

export default {
  path: '/treasury',
  component: () => import('../../../views/admin/treasury/index.vue'),
  children: [
    {
      path: '/',
      redirect: '/error-404'
    },
    banks,
    paymentGateways,
    cashBoxes,
    receive,
    cost,
    payment,
    internalFundsTransfer,
    transactions
  ]
}