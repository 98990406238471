// backup

export default {
  title: 'Backup',

  status: {
    all: 'All',
    saving: 'Saving',
    saved: 'Saved',
    retrieved: 'Restored'
  },

  notifications: {
    insert: {
      success: 'The backup file was created successfully',
      error: 'Error creating backup file'
    },

    recovery: {
      success: 'The request was sent successfully',
      error: 'This backup is not recoverable',
      cantSend: 'The request sent an error'
    }
  },

  confirmations: {
    insert: {
      title: 'Insert backup',
      body: 'Are you sure you want to insert back up?'
    },

    recovery: {
      title: 'Recovery backup',
      body: 'Are you sure you want to recovery back up?'
    }
  },

  list: {
    table: {
      header: {
        rowNumber: 'row',
        date: 'Date',
        status: 'Status',
        recovery: 'Recovery'
      }
    },

    sortTypes: {
      newest: 'جدیدترین',
      oldest: 'قدیمی ترین'
    }
  }
}