// =============================================================================
// TICKETS PAGES LAYOUTS
// =============================================================================

export default {
  path: '/tickets',
  component: () => import('../../views/admin/tickets/index.vue'),
  children: [
    {
      path: 'support',
      component: () => import('../../views/admin/tickets/supportTicket/index.vue'),
      children: [
        {
          path: '/',
          name: 'supportTickets',
          component: () => import('../../views/admin/tickets/supportTicket/list/ticketsSupportList.vue'),
          meta: {
            permission: 'support_ticket.show',
            pageTitle: 'تیکت ها',
            pageTitleI18n: 'tickets.support.list.title'
            // rule: 'editor'
          }
        },
        {
          path: 'insert',
          name: 'insertSupportTicket',
          component: () => import('../../views/admin/tickets/supportTicket/insert/insertSupportTicket.vue'),
          meta: {
            permission: 'support_ticket.create',
            pageTitle: 'افزودن تیکت جدید',
            pageTitleI18n: 'tickets.support.insert.title'
            // rule: 'editor'
          }
        },
        {
          path: ':id',
          name: 'supportTicketDetail',
          component: () => import('../../views/admin/tickets/supportTicket/profile/ticketSupportDetail.vue'),
          meta: {
            permission: 'support_ticket.show',
            pageTitle: '',
            pageTitleI18n: 'tickets.support.detail.title'
            // rule: 'editor'
          }
        }
      ]
    },
    {
      path: 'organization',
      component: () => import('../../views/admin/tickets/organizationTicket/index.vue'),
      children: [
        {
          path: '/',
          name: 'organizationTickets',
          component: () => import('../../views/admin/tickets/organizationTicket/list/ticketsOrganizationList.vue'),
          meta: {
            permission: 'organizational_ticket.show',
            pageTitle: 'تیکت ها',
            pageTitleI18n: 'tickets.organization.list.title'
            // rule: 'editor'
          }
        },
        {
          path: 'insert',
          name: 'insertOrganizationTicket',
          component: () => import('../../views/admin/tickets/organizationTicket/insert/insertOrganizationTicket.vue'),
          meta: {
            permission: 'organizational_ticket.create',
            pageTitle: 'افزودن تیکت جدید',
            pageTitleI18n: 'tickets.organization.insert.title'
            // rule: 'editor'
          }
        },
        {
          path: ':id',
          name: 'organizationTicketDetail',
          component: () => import('../../views/admin/tickets/organizationTicket/profile/ticketOrganizationDetail.vue'),
          meta: {
            permission: 'organizational_ticket.show',
            pageTitle: '',
            pageTitleI18n: 'tickets.organization.detail.title'
            // rule: 'editor'
          }
        }
      ]
    }
  ]
}