<template>
  <div class="u-input u-radio-input">
    <div class="u-input-box text-subtitle" :class="[{'is-not-valid': !data.is_valid , 'disabled': disabled}, dir || {}]" :style="getStyles">

      <div class="u-input-label-box" :class="{'clickable': clickable}">
        <div class="u-input-label-box-start"></div>
        <div v-if="labelPlaceholder || label" class="u-input-label-box-center" :class="[labelPosition]">
          <span class="text-subtitle" :class="[{'is-focus': data.value.toString().length || label, 'text-body-1': labelPosition === 'inside'}]">{{ labelPlaceholder || label }}</span>
        </div>
        <div class="u-input-label-box-end"></div>
      </div>
      <div class="u-input-radio-items" :class="classes">
        <template v-for="(item, key) in options">
          <div :key="key" class="u-input-radio-item">
            <label :for="`${id}_${key}`" class="text-h5-light">{{ item.label }}</label>
            <input :id="`${id}_${key}`"
                   type="radio"
                   :key="key"
                   :name="id"
                   :value="item.value"
                   :disabled="disabled"
                   @change="handleInputValue"
                   v-model="data.value"/>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "URadioInput",
    model: {
      prop: 'value',
      event: 'input'
    },
    props: {
      id: {
        type: String,
        default: () => { return '' }
      },
      value: {
        type: Object,
        default () { return {} }
      },
      options: {
        type: Array,
        default: () => []
      },
      defaultOption: {
        type: Object,
        default: () => {}
      },
      dir: {
        type: String,
        default: () => ''
      },
      classes: {
        type: Object,
        default () { return {} }
      },
      styles: {
        type: Object,
        default () { return {} }
      },
      label: {
        type: String,
        default: () => { return '' }
      },
      labelPlaceholder: {
        type: String,
        default: () => { return '' }
      },
      labelOutBox: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      clickable: {
        type: Boolean,
        default: () => { return false }
      },
      labelPosition: {
        type: String,
        default: 'outline'
      },
    },

    data () {
      return {
        data: {
          value: '',
          is_valid: true
        }
      }
    },

    computed: {
      getStyles () {
        const styles = JSON.parse(JSON.stringify(this.styles))
        if (!styles.width) {
          // styles.width = '230px'
        }

        return styles
      }
    },

    created() {
      if (Object.keys(this.value).length) {
        this.initValues()
      }
    },

    methods: {
      handleInputValue () {
        if (!this.disabled) {
          this.$emit('input', {value: this.data.value, isValid: this.data.is_valid})
        }
      },
      initValues () {
        this.data.value = this.value.value
      },
    },

    watch: {
      value: {
        handler () {
          this.initValues()
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .u-radio-input {
    .u-input-box {
      position: relative;
      display: flex;
      justify-content: space-around;
      min-height: 35px;
      flex-wrap: wrap;

      .u-input-radio-items {
        z-index: 10;
        display: flex;
        justify-content: space-around;
        min-height: 35px;
        flex-wrap: wrap;
        width: 100%;

        .u-input-radio-item {
          display: flex;
          align-items: center;
          width: max-content;
          direction: rtl;

          label {
            direction: ltr;
            margin-left: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          input[type='radio'] {
            height: 15px;
            width: 15px;
            margin-top: 0;
          }
        }

        &.flex-start {
          .u-input-radio-items {
            justify-content: flex-start;

            .u-input-radio-item {
              justify-content: flex-end;
              width: 33%;
            }
          }
        }
      }
    }
  }
</style>
