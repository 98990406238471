export default {
  regex: {
    hour: /^((([0-1]?[0-9]|2[0-3]))|24)$/,
    time: /^((([0-1]?[0-9]|2[0-3]):[0-5][0-9])|24:00)$/,
    date: /^\d{4}[\/](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/,
    dateTime: /^\d{4}[\/](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[ ]((([0-1]?[0-9]|2[0-3]):[0-5][0-9])|24:00)$/,

    utf8: /^[a-zA-Z ,.'-]{3,}$/,

    OTPCode: /^[0-9۰-۹٠-٩]{5}$/,

    name: /^[a-zA-Z ,.'-]{3,}$/,

    // user
    user: {
      name: /^([a-zA-Z]+)$/,
      lastName: /^[a-zA-Z]{3,}$/,
      phoneNumber: /^09[0-9۰-۹٠-٩]{9}$/,
      phoneNumberStartWith: '09',
      email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      addressName: /^[a-zA-Z ,.'-]{3,}$/,
      address: /^([a-zA-Z0-9 ,.'-]{5,})$/,
      postCode: /^$|^[0-9۰-۹٠-٩]{10}$/,
    },

    coaches: {
      receive: {
        transferId: /^[0-9_\/-]{4,70}$/,
      }
    },

    // character
    character: {
      name: /^([A-Za-z0-9]+)$/
    },

    // refund
    refund: {
      name: /^([A-Za-z0-9 ,.'-]+)$/,
      percent: /^([0-9۰-۹٠-٩]{1,2}|100|0)$/
    },

    //message
    message: {
      key: /^([A-Z0-9]+)$/,
      template: /^([a-zA-Z0-9]+)$/,
    },

    // sale
    sale: {
      addon: {
        name: /^([A-Za-z0-9 ,.'-]+)$/,
      },

      discount: {
        percent: /^([0-9۰-۹٠-٩]{1,2}|100|0)$/,
        price: /^([0-9۰-۹٠-٩]+)$/,
      }
    },

    // treasury
    treasury: {
      bank: {
        name: /^([a-zA-Z0-9 ,.'-]{2,})$/,
        shabaNumber: /^\d{24}$/,
        accountNumber: /^\d{6,15}$/
      },

      paymentGateway: {
        name: /^[A-Za-z ()0-9]{5,50}$/,
        transferId: /^[0-9_\/-]{4,70}$/,
      },

      receive: {
        transactionId: /^[A-Za-z0-9_-]{5,70}$/
      }
    },

    //event
    event: {
      subject: /^[A-Za-z \-\ (.)0-9]{5,50}$/,
      content: /^[#_*\[\],،:\>A-Za-z a-zA-Z \-\ \r\n(.)0-9]{5,400}$/,
    },

    // access group
    accessGroup: {
      name: /^[A-Za-z ]{3,}$/,
    },

    // setting
    setting: {
      address: {
        name: /^([A-Za-z ,.'-]{5,})$/,
      },

      employee: {
        name: /^([A-Za-z ,.'-]+)$/,
        nationalCode: /^\d{10}$/
      },

      tell: {
        whatsApp: /^\+989[0-9]{9}$/,
      }
    },

    // club info
    company: {
      address: /^([A-Za-z ,.'-]{5,})$/,
    },

    customEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

    shaba: {
      prefix: 'AE',
      pattern: /AE[0-9]{24}/
    },

    address: {
      name: /^[A-Za-z ]{3,}$/,
      address: /^[/ A-Za-z\.\-\s\,0-9۰-۹٠-٩]{3,}$/,
      plaque: /^[0-9۰-۹٠-٩]{1,}$/,
      postalCode: /^[0-9۰-۹٠-٩]{10}$/,
    }

  },

  moment: {
    year: 'YYYY',
    month: 'MM',
    yearMonth: 'YY/MM',
    second: 'ss',
    time: 'HH:mm',
    date: 'jYYYY/jMM/jDD',
    dateHour: 'YYYY/M/D HH',
    dateTime: 'jYYYY/jMM/jDD HH:mm',
    dateWithWeek: 'dddd D MMMM',
    fullDateWithWeek: 'dddd D MMMM YYYY',
    fullDateTime: 'YYYY-M-D HH:mm:ss',

    weekDay: {
      1: 'Saturday',
      2: 'Sunday',
      3: 'Monday',
      4: 'Tuesday',
      5: 'Wednesday',
      6: 'Thursday',
      7: 'Friday'
    }
  }
}
