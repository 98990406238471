<template>
  <div class="draggable-dynamic-table-row" :class="[$vs.rtl ? 'dir-rtl' : 'dir-ltr']" @click="handleRowClicked(row_index)">
    <template v-for="(column, column_index) in headers">
      <div v-if="!column.hasOwnProperty('show') || column.show || column.locked"
           :key="`header_${column_index}`"
           class="root-body-cell"
           :class="[{'overflow-visible': column.dropDown, 'ellipsis-overflow-cell': column.hasOwnProperty('ellipsis') ? column.ellipsis : true}]"
           :style="columnsStyles[column.field] || getRowStyles(column)">

        <input-box v-if="column.editable"
                   :align="column.align || 'center'"
                   :return-value="column.returnValue"
                   :ref="`input_${column.field}`"
                   :type="column.valueType ? column.valueType : ''"
                   :currency="row[`${column.field}_preContent`] && !column.hasOwnProperty('description') ? row[`${column.field}_preContent`] : column.hasOwnProperty('currency') ? row[column.field].currency || $locale.currency() : ''"
                   @enter:keyUp="column.valueEnterKeyUpEvent ? $emit('keyupEvent', {event: column.valueEnterKeyUpEvent, value: {index: row_index, data: $event}}): ''"
                   @focus="$emit('editableColumnClick', column)"
                   @inputValueChange="handleSetStatusFlag(row),
                                 column.footer ? $emit('setFooterValue', column) : '',
                                 column.valueEvent ? $emit('valueEvent', {column, value: {index: row_index, data: $event}}): '',
                                 $emit('inputValueChange', {value: $event, row, column})"
                   v-model="row[column.field]"/>


        <custom-select v-else-if="column.dropDown"
                       :default="row[column.field].selected"
                       :options="row[column.field].list || column.list"
                       @suggest:selected="$emit('dropDown:selected', $event)"
                       v-model="row[column.field].selected"/>

        <custom-check-box ref="customDraggableDynamicTableCheckBox"
                          v-else-if="column.selectable"
                          v-model="selectedData"
                          :row-selectable="options.hasOwnProperty('rowSelectable') ? options.rowSelectable : true"
                          :multi="column.hasOwnProperty('multiSelect') ? column.multiSelect : true"
                          :value="selectedData[row_index]"/>

        <span v-else-if="column.autoIncrement"
              class="ellipsis-overflow ltr">
                      {{ row_index + 1 }}
                  </span>

        <actions-box v-else-if="column.actions"
                     :class="column.classes ? column.classes : ''"
                     :color="column.action.color"
                     :text="column.action.text"
                     :icon="column.action.icon"
                     :icon-pack="column.action.iconPack"
                     :type="column.action.type || 'reset_input'"
                     :show="column.showAction"
                     :look-field="column.lookField"
                     :data="row"
                     @clicked="handleActionButton(column, row)"/>

        <lazy-image v-else-if="typeof row[column.field] === 'object' && row[column.field].type === 'img'"
                    :src="row[column.field].src"
                    :alt="row[column.field].src"
                    :class="row[column.field].class ? row[column.field].class : ''"/>

        <router-link v-else-if="typeof row[column.field] === 'object' && row[column.field].hasOwnProperty('route') && row[column.field].route !== null && row[column.field].route !== ''" :to="row[column.field].route" :target="row[column.field].target || ''">
          <span class="ellipsis-overflow" :class="[row[column.field].classes ? row[column.field].classes : 'ltr text-primary', `text-${column.align || 'center'}`]" @contextmenu.stop="handleContextMenuClicked($event, row[column.field], row[column.field].value, column.field)">{{ row[column.field].value }}</span>
        </router-link>

        <span v-else-if="typeof row[column.field] === 'object'"
              @click="column.valueClickEvent ? $emit('clickEvent', {event: column.valueClickEvent, value: {index: row_index, data: $event}}): ''"
              @contextmenu.stop="handleContextMenuClicked($event, row, row[column.field].value, column.field)"
              class="ellipsis-overflow"
              :class="[row[column.field].classes ? row[column.field].classes : 'ltr', row[column.field].type === 'price' && row[column.field].value !== 0 ? 'price' : '', `text-${column.align || 'center'}`]"
              :style="row[column.field].styles ? row[column.field].styles : ''">

                        {{ column.type === 'relation' ? getRelationValue(row, column) : '' }}

                        {{
                          row[column.field].type === 'price' ? addComma(row[column.field].value, true) || '-' : row[column.field].value
                        }}
                        <span class="currency" v-if="row[column.field].type === 'price' && !column.hasOwnProperty('description')">{{ row[column.field].currency || $locale.currency() }}</span>
                        <template  v-if="row[column.field].preContent">
                          <span class="currency" :class="typeof row[column.field].preContent === 'object' && row[column.field].preContent.hasOwnProperty('class') ? row[column.field].preContent.class : ''">
                            {{ row[column.field].preContent.hasOwnProperty('value') ? row[column.field].preContent.value : row[column.field].preContent }}
                          </span>
                        </template>
                    </span>

        <span v-else
              @click="column.valueClickEvent ? $emit('clickEvent', {event: column.valueClickEvent, value: {index: row_index, data: $event}}): ''"
              @contextmenu.stop="handleContextMenuClicked($event, row, row[column.field], column.field)" class="ellipsis-overflow"
              :class="[column.filterType === 'date' && $vs.rtl ? 'rtl' : 'ltr', `text-${column.align || 'center'}`]">{{ row[column.field] }}</span>

        <div v-if="typeof row[column.field] === 'object' && row[column.field].hasOwnProperty('watermark')" class="watermark-cell">
          <lazy-image :src="row[column.field].watermark.value"
                      :alt="row[column.field].watermark.value"
                      class="watermark-img"/>
        </div>

        <div v-if="column.suggestPrompt"
             class="suggest-modal-button"
             @click="$emit('suggestPrompt:opened', row_index), handleCloseSuggestBox(`suggestsList_${column.field}_${row_index}`)">
          <custom-icon height="22px" width="22px" icon="LIST" color-code="#00bcd4" />
        </div>

        <div v-if="column.suggestPrompt"
             class="new-suggest-modal-button"
             @click="$emit('newSuggestPrompt:opened', row_index), handleCloseSuggestBox(`suggestsList_${column.field}_${row_index}`)">
          <custom-icon height="22px" width="22px" icon="PLUS" color-code="#28C76F" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import axios from "@/http/axios/index"
  import {addComma} from '@/assets/js/functions'
  import CustomCheckBox from '@/components/customCheckBox/customCheckBox'
  import InputBox from '@/components/draggableDynamicTable/InputBox'
  import ActionsBox from '@/components/draggableDynamicTable/actionsBox'
  import SuggestBox from '@/components/draggableDynamicTable/suggestBox'
  import CustomSelect from '@/components/customSelect/customSelect'
  import CustomIcon from '../customIcon/customIcon'
  import LazyImage from '@/components/lazyImage/lazyImage'
  import {customParseFloat} from "../../assets/js/functions";
  export default {
    name: "draggableDynamicTabaleRow",
    components: {
      CustomCheckBox,
      InputBox,
      ActionsBox,
      SuggestBox,
      CustomSelect,
      CustomIcon,
      LazyImage
    },
    props: {
      options: {
        type: Object,
        default: {}
      },
      selectedData: {
        type: Array,
        default: []
      },
      headers: {
        type: Array,
        default: []
      },
      row: {
        type: Object,
        default: {}
      },
      row_index: {
        type: Number,
        default: 0
      },
      headerSelectable: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        columnsStyles: {}
      }
    },
    methods: {
      customParseFloat,
      getRowStyles (column) {
        const styles = []
        if (column.width) styles.push(`width: ${column.width}`)
        if (column.minWidth) styles.push(`min-width: ${column.minWidth}px`)
        if (column.maxWidth) styles.push(`max-width: ${column.maxWidth}px`)

        this.columnsStyles[column.field] = `${styles.join('; ')};`
        return this.columnsStyles[column.field]
      },
      handleSetStatusFlag (item) {
        if (item.flag) {
          item.flag = 'UPDATED'
        }
      },
      handleCloseSuggestBox (ref) {
        if (this.$refs[ref]) {
          this.$refs[ref][0].show = false
        }
      },
      handleContextMenuClicked (event, row, value, column) {
        let payload = {
          actions: {
            clipboard: value || ''
          },
          event: event
        }
        if (row[column] && row[column].route) {
          payload.actions.newTabLink = row[column].route
          payload.actions.copyLink = axios.defaults.baseURL.replace('/api', '') + this.$router.resolve(row[column].route).href
        } else if (row.route) {
          payload.actions.newTabLink = row.route
          payload.actions.copyLink = axios.defaults.baseURL.replace('/api', '') + this.$router.resolve(row.route).href
        }
        this.$store.dispatch('helper/contextMenuClicked', payload)
      },
      getRelationValue (row, column) {
        // eslint-disable-next-line no-useless-escape
        const regex = /{[0-9]*}|([A-Za-z]+)|[\+]|[\*]|[\-]|[\/]/g
        const params = column.relation.match(regex)
        let values = 0
        let prefix = 1
        let operator = ''

        for (let i = 0; i < params.length; i++) {
          if (params[i].toString().indexOf('{') > -1 && params[i].toString().indexOf('}') > -1) {
            prefix = customParseFloat(params[i].toString().replace('{', '').replace('}', '').toString().split(',').join('')) || 1
            // eslint-disable-next-line no-useless-escape
          } else if (/\+|\-|\*|\//g.test(params[i])) {
            if (/\*/g.test(params[i])) {
              if (customParseFloat(row[params[i - 1]].toString().split(',').join('') || 0) === 0 || customParseFloat(row[params[i + 1]].toString().split(',').join('') || 0) === 0) {
                values = 0
                break
              }
            }
            operator = params[i]
          } else if (values === 0) {
            if (typeof row[params[i]] === 'number') values = customParseFloat(prefix * row[params[i]])
            else if (typeof row[params[i]] === 'string') values = customParseFloat(prefix * (customParseFloat(row[params[i]].toString().split(',').join('')) || 0))
            else if (typeof row[params[i]] === 'object') values = customParseFloat(prefix * customParseFloat((row[params[i]].value !== '' ?  row[params[i]].value : row[params[i]].placeHolder).toString().split(',').join('')))
            prefix = 1
          } else {
            switch (operator) {
              case '+':
                if (typeof row[params[i]] === 'number') values = customParseFloat(values + customParseFloat(prefix * customParseFloat(row[params[i]])))
                else if (typeof row[params[i]] === 'string') values = customParseFloat(values + customParseFloat(prefix * (customParseFloat(row[params[i]].toString().split(',').join('')) || 0)))
                else if (typeof row[params[i]] === 'object') values = customParseFloat(values + customParseFloat(prefix * customParseFloat((row[params[i]].value !== '' ?  row[params[i]].value : row[params[i]].placeHolder).toString().split(',').join(''))))
                prefix = 1
                operator = ''
                break

              case '-':
                if (typeof row[params[i]] === 'number') values = customParseFloat(values - customParseFloat(prefix * customParseFloat(row[params[i]])))
                else if (typeof row[params[i]] === 'string') values = customParseFloat(values - customParseFloat(prefix * (customParseFloat(row[params[i]].toString().replaceAll(',', '')) || 0)))
                else if (typeof row[params[i]] === 'object') values = customParseFloat(values - customParseFloat(prefix * customParseFloat((row[params[i]].value !== '' ?  row[params[i]].value : row[params[i]].placeHolder).toString().split(',').join('') || 0)))
                prefix = 1
                operator = ''

                if (values < 0) {
                  if (typeof row[params[i]] === 'number') row[params[i]] = customParseFloat(row[params[i]].toString().substring(0, row[params[i]].length - 1))
                  else if (typeof row[params[i]] === 'string') {
                    // console.log(typeof row[params[i]])
                    row[params[i]] = row[params[i]].split(',').join('').substring(0, row[params[i]].length - 1)
                  }
                }
                break

              case '*':
                if (typeof row[params[i]] === 'number') values = customParseFloat(values * customParseFloat(prefix * customParseFloat(row[params[i]])))
                else if (typeof row[params[i]] === 'string') values = customParseFloat(values * customParseFloat(prefix * (customParseFloat(row[params[i]].toString().replaceAll(',', '')) || 0)))
                else if (typeof row[params[i]] === 'object') values = customParseFloat(values * customParseFloat(prefix * customParseFloat((row[params[i]].value !== '' ?  row[params[i]].value : row[params[i]].placeHolder).toString().split(',').join(''))))
                prefix = 1
                operator = ''
                break

              case '/':
                if (typeof row[params[i]] === 'number') values = customParseFloat(values / customParseFloat(prefix * row[params[i]]))
                else if (typeof row[params[i]] === 'string') values = customParseFloat(values / customParseFloat(prefix * (customParseFloat(row[params[i]].toString().replaceAll(',', '')) || 0)))
                else if (typeof row[params[i]] === 'object') values = customParseFloat(values / customParseFloat(prefix * customParseFloat((row[params[i]].value !== '' ?  row[params[i]].value : row[params[i]].placeHolder).toString().split(',').join(''))))
                prefix = 1
                operator = ''
                break
            }
          }
        }

        switch (column.relationType) {
          case 'price':
            values = addComma(values) /*+  this.$t('currency)*/
            break
        }

        row[column.field].value = values
        this.$nextTick(() => {
          this.$emit('setFooterValue', column)
        })
      },
      handleActionButton (header, item) {
        this.$emit('actionBtnClicked', header.event ? header.event : 'clicked', item)

        switch (header.action.type) {
          case 'reset_input':
            this.updateInputValue(header, item)
            break

          case 'button':
          case 'hideAfterClick':
            break

          default:
            this.updateInputValue(header, item)
            break
        }
      },
      updateInputValue (header, item) {
        this.$refs[`input_${header.field}`][0].updateFirstValue()
      },
      handleRowClicked (index) {
        if (this.headerSelectable && (!this.options.hasOwnProperty('rowSelectable') || this.options.rowSelectable)) {
          if (this.$refs.customDraggableDynamicTableCheckBox[0]) {
            this.$refs.customDraggableDynamicTableCheckBox[0].setChecked()
          }
        }
        this.$emit('row:clicked')
      },
      addComma (val, blur) {
        return addComma(val, blur)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .draggable-dynamic-table-row {
    display: flex;
    color: #626262;
    text-decoration: none;
    min-width: 100%;
    cursor: pointer;

    &.dir-rtl {
      flex-direction: row-reverse;
    }

    &.dir-ltr {
      flex-direction: row;
    }

    span.ellipsis-overflow {
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 10px;
      line-height: 25px;

      &.price {
        font-weight: 600;
        direction: ltr;
      }

      .currency {
        color: #888888;
        font-size: 13px;
        font-weight: 400;
        margin-left: 5px;
      }
    }

    .root-body-cell {
      display: flex;
      justify-content: space-evenly;
      position: relative;
      flex-grow: 1;

      &.ellipsis-overflow-cell {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .ellipsis-overflow {
          white-space: nowrap;
        }
      }

      &.overflow-visible {
        overflow: visible;
      }

      .suggest-modal-button, .new-suggest-modal-button {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 49000;
        margin: auto;
        //height: fit-content;
        right: 40px;
        width: 25px !important;
        height: 25px !important;
        padding: 0;
        border: 1px solid #00bcd4;
        border-radius: .5rem;
        color: #00bcd4;
        background: white;
      }

      .new-suggest-modal-button {
        right: 10px;
        padding-right: 3px;
        border: 1px solid #28C76F;
        color: #28C76F;
      }

      .useral-custom-element-select {

        .selected {
          border: none !important;

          &:after {
            border-color: #cecece transparent transparent transparent;
          }
        }

        .items {
          right: -1px;
          left: -1px;
          top: 45px;
          background-color: #e5e5e5 !important;

          div {
            line-height: 50px;

            &:hover {
              background-color: #cecece !important;
            }
          }
        }
      }

      .watermark-cell {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .watermark-img {
          height: 50px;
          width: 50px;
          object-fit: contain;
          border-radius: 0.5rem;
          overflow: hidden;
        }
      }
    }
  }
</style>
