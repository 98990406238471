<template>
  <div class="icon-component" :style="[{height: height + '!important'},{width: width + '!important'}]">
    <div class="icon" :class="{'icon-hover': cursorPointer}"
         :style="[{'-webkit-mask': 'url(' + imagePath(icon, iconType)+')'},
               {'mask': 'url(' + imagePath(icon, iconType)+')'},{width: width},
               {height: height},{backgroundColor: colorCode || colors[color] || colors.primary}]"></div>
    <!--    <span class="icon" :class="{'icon-hover': cursorPointer}"
              :style="[{backgroundImage: 'url(' + imagePath(icon, iconType)+')'},{width: width},
                   {height: height}]"></span>-->
    <!--    <img style="fill: #ff4444" :width="width" :height="height" :src="imagePath(icon, iconType)">-->
  </div>
</template>

<script>
export default {
  name: 'customIcon',
  props: {
    width: {
      type: String,
      default: '25px'
    },
    height: {
      type: String,
      default: '25px'
    },
    icon: '',
    iconType: {
      type: String,
      default: 'svg'
    },
    color: {
      type: String,
      default: () => {
        return 'primary'
      }
    },
    colorCode: {
      type: String,
      default: () => {
        return ''
      }
    },
    cursorPointer: true
  },
  data () {
    return {
      colors: {
        'primary': '#3891bc',
        'success': '#28c76f',
        'warning': '#ffa146',
        'danger': '#ea5455',
        'gray': '#cecece',
        'dark-gray': '#626262'
      }
    }
  },
  methods: {
    imagePath (name, type) {
      return require(`@/assets/icons/${  name  }.${  type}`)
    }
  }
}
</script>

<style lang="scss" scoped>

div.icon-component {
  position: relative;

  .icon {
    display: inline-block;
    background-size: cover;
    mask-repeat: no-repeat !important;
    margin: auto !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    mask-position: center !important;

    &.icon-hover {

      &:hover, &:focus {
        cursor: pointer;
        filter: invert(27%) sepia(5%) hue-rotate(10deg) brightness(100%) contrast(90%);
      }
    }
  }
}

</style>
