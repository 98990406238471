// =============================================================================
// SALES DISCOUNT PAGES LAYOUTS
// =============================================================================

export default {
  path: 'discount-groups',
    component: () => import('../../../views/admin/sales/discount/index.vue'),
  children: [
  {
    path: '/',
    name: 'discountGroups',
    component: () => import('../../../views/admin/sales/discount/list/saleDiscountList.vue'),
    meta: {
      permission: 'discount_group.show',
      pageTitle: 'تخفیفات رزرو',
      pageTitleI18n: 'sales.discount.title'
      // rule: 'editor'
    }
  }
]
}