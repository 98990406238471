export default {
  setClubInfo ({commit}, payload) {
    let club = ''
    if (Object.keys(payload).length > 0) {
      club = {
        'club_code': '1',
        ...payload
      }
    } else {
      club = {'club_code': '1', 'club_name': 'سامانه مدیریت رزرواسیون', 'club_logo': ''}
    }
    commit('SET_CLUB_INFO', club)
  }
}
