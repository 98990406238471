import activityLogs from './activityLogs'
import profile from './profile'
import coaches from './coaches'
import courts from './courts'
import times from './times'
import terms from './terms'
import characters from './characters'
import refunds from './refunds'
import companies from './companies'
import backup from './backup'
import setting from './setting'
import accountancy from './accountancy'
import users from './users'
import documents from './documents'
import sales from './sales'
import closed from './closed'
import treasury from './treasury'
import report from './report'
import reports from './reports'
import tickets from './tickets'
import events from './events'

export default {
  activityLogs,
  profile,
  coaches,
  courts,
  times,
  terms,
  characters,
  refunds,
  companies,
  backup,
  setting,
  accountancy,
  users,
  documents,
  sales,
  closed,
  treasury,
  report,
  reports,
  tickets,
  events,

  preSheba: 'AE',
  whatsAppPreNumber: '971',

  // navMenuItems Words
  dashboard: 'Dashboard',

  shortcuts: {
    calendar: 'Calendar',
    reserve: 'Booking',
    receive: 'Receive'
  },

  save: 'Save',
  cancel: 'Cancel',

  logout: 'Log out',

  // gender
  genderTypes: {
    all: 'All',
    man: 'mr',
    dynamicMan: 'mr. ',
    woman: 'ms '
  },

  // alert title
  alert: {
    duplicateRequest: {
      title: 'Warning',
      message: 'Your previous request is being processed, please wait'
    },
    error: {
      title: 'Error',

      accessDenied: 'You do not have access to this activity'
    },
    warning: {
      title: 'Warning',
      repeatedRequestText: 'Your previous request is being processed, please wait'
    },
    message: {
      title: 'Message',
      pleaseWait: 'Request sent. please wait...'
    }
  },

  // navbar actions
  navbar: {
    back: 'Back',
    save: 'Save',
    from: 'from ',
    until: ' to ',
    dynamicFinancialYear: 'financial year {start} to {end}',

    notification: {
      newMessage: 'New notification',
      showAll: 'View all notifications'
    }
  },

  wizard: {
    actions: {
      previous: 'Previous step',
      next: 'Next',
      confirm: 'Confirm'
    },

    club: {
      title: 'Club wizard',
    }
  },

  // table statics
  draggableTable: {

    filter: {

      types: {
        search: 'Contain',
        equals: 'Equal',
        notEqual: 'Not equal',
        lessThanEquals: 'Less',
        greaterThanEquals: 'More'
      }
    }
  },

  // login page
  login: {
    title: 'Sign in',

    labels: {
      codeValidate: 'Code validity',
      second: 'Second',
      resendCode: 'Resend Code',
      sendOtp: 'Send activation code'
    },

    clubTitle: 'Admin panel {name}',
    confirmPhoneNumber: 'Phone number confirmation',
    getPhoneNumberMessage: 'Please enter your phone number to be send the <b> verification code </b>',
    otpSend: 'The verification code was sms to your phone number.',

    getPhoneNumberStepMessage1: 'Please enter your phone number. A password ',
    getPhoneNumberStepMessage2: '(five-digit code)',
    getPhoneNumberStepMessage3: ' will be sent to confirm your number.',
    phoneNumber: 'Phone Number',
    phoneNumberIsNotValid: 'The phone number was not entered correctly',
    phoneNumberIsNull: 'Phone number is empty',

    otpCodeSentStepMessage1: 'A 5-digit code ',
    otpCodeSentStepMessage2: ' was sent to the phone number. Please enter the code',
    otpCodeIsNotValid: 'The validation code is wrong',
    otpCodeIsNull: 'The validation code is empty',
    retrieveTheCode: 'Retrieve the code',

    loginToPanel: 'Login to {name} panel',
    resendOTPMessage: 'Select one of the following methods to retrieve the code',
    otpCall: 'Call {number} will be made in a few moments',
    resendOTPTypes: {
      sms: 'SMS',
      tel: 'Call'
    },

    otp: 'Disposable password',
    otpSendDynamic: 'The validation code was sent to {number}.',

    getSuperAdminTokenStepMessage: 'Please enter your token.',
    token: 'System Manager Token',

    buttons: {
      getOTPCode: 'Get the code',
      login: 'Sign in'
    },

    notification: {
      userNotFound: 'No user found with this phone number!',
      accessError: 'Your access to the system has been blocked!',

      login: {
        title: 'Message',
        message: 'You have successfully Sign in'
      },

      review: {
        title: 'Authentication',
        message: 'The system is verifying your identity!'
      },

      logout: {
        title: 'Message',
        message: 'You have successfully Sign out'
      },

      forceLogout: {
        title: 'Message',
        message: 'Please sign in again due to not using the system for a long time'
      }
    },

    validators: {
      phoneStart: 'The phone number starts with {startWith}.',
      phoneNumberLength: 'Phone number must be {length} digits'
    }

  },

  smsPanel: 'SMS panel',

  // opening inventories
  opening: {
    users: {
      title: 'Beginning user'

    },

    cashBoxes: {
      title: 'Beginning cash register'

    },

    banks: {
      title: 'Beginning banks'

    }
  },

  sale: {
    title: 'Sale'
  },

  club: {
    setting: {
      title: 'Club configuration'
    }
  },

  // draggable dynamic table
  draggableDynamicTable: {

    filters: 'Filters',

    activeFilters: {
      title: 'Applied filters'
    },

    setting: {
      showColumns: 'Show columns'
    },

    notifications: {
      loading: 'Loading information ...',
      error: 'Received information error',
      empty: 'There is no information'
    },

    actions: {
      print: 'Print',
      download: 'Download',
      setting: 'Table settings'
    }
  },

  // accessTreeCheckBox
  accessTreeCheckBox: {
    labels: {
      chooseCategory: 'Select access'
    }
  },

  // custom dialog
  customDialog: {
    confirm: 'Confirm',
    cancel: 'Cancel'
  },

  customFileInput: {
    uploaded: 'Your photo has been uploaded'
  },

  // custom date picker input
  customDatePickerInput: {
    invalidText: 'The entered field is invalid'
  },

  // custom number input
  customNumberInput: {
    invalidText: 'The entered field is invalid'
  },

  // custom select input
  customSelectInput: {
    invalidText: 'The entered field is invalid'
  },

  // custom validate input
  customValidateInput: {
    invalidText: 'The entered field is invalid'
  },

  selectTime: {
    withoutPrice: 'No price',
    until: ' to '
  },

  // date picker
  datePicker: {
    labels: {
      submit: 'Confirm',
      cancel: 'Cancel',
      now: 'Now',
      nextMonth: 'next month',
      prevMonth: 'last month'
    }
  },

  // contextMenu
  contextMenu: {
    actions: {
      openNewTab: 'Open in new tab',
      copyLink: 'Copy link address',
      copy: 'Copy',
      paste: 'Paste'
    },
    notifications: {
      clipboard: 'Copied to clipboard!'
    }
  },

  // mixins
  documentDescriptions: {
    receive: {
      description: 'Receive document No. {id}',
      dynamicDescription: 'Receive document | {description}'
    },
    payment: {
      description: 'Payment document No. {id}',
      dynamicDescription: 'Payment document | {description}'
    },
    cost: {
      description: 'Cost document No. {id}',
      dynamicDescription: 'Cost document | {description}'
    },
    beginningInventory: {
      description: 'Beginning inventory document'
    },
    internalFundTransfer: {
      description: 'Withdrawal from {payer} Deposit to {payee}'
    },
    receivePaymentGateway: {
      description: 'Receive payment gateway from {payer} Deposit to {payee}'
    },
    sale: {
      description: 'Sale invoice No. {id}'
    },
    returnSale: {
      description: 'Return sale invoice No. {id}'
    },
    purchase: {
      description: 'Purchase invoice No. {id}'
    },
    returnPurchase: {
      description: 'Return purchase invoice No. {id}'
    },
    warehouse_receipt: {
      on_way_description: 'Warehouse receipt for {invoice_type} No. {invoice_id}',
      description: 'Warehouse receipt No. {receipt_id}'
    },
    inventory_transfer: {
      description: 'Warehouse transfer No. {receipt_id}'
    },
    warehouse_requisition: {
      waiting_to_send_description: 'Waiting to send warehouse requisition for {invoice_type} No. {invoice_id}',
      description: 'Warehouse requisition No. {receipt_id}',
      description_dynamic: 'Warehouse requisition {receipt_id} for {invoice_type} No. {invoice_id}'
    }
  },

  //Button
  create: 'Insert'
}
