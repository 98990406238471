<template>
  <div class="u-input custom-card-number-input">
    <label class="u-input-box" :class="{'is-not-valid': !card.isValid}" :style="getStyles">
<!--      <span class="input-label" :class="{'is-focus': card.value.toString().length || label.length}">{{ labelPlaceholder || label }}</span>-->

      <div class="u-input-label-box">
        <div class="u-input-label-box-start"></div>
        <div v-if="labelPlaceholder || label" class="u-input-label-box-center" :class="[labelPosition]">
          <span class="text-subtitle" :class="[{'is-focus': card.value.toString().length || label, 'text-body-1': labelPosition === 'inside'}]">{{ labelPlaceholder || label }}</span>
        </div>
        <div class="u-input-label-box-end"></div>
      </div>
      <input type="text"
             :class="[{'disabled': disabled}, $vs.rtl ? 'rtl' : 'ltr']"
             @input="handleInputValue"
             :placeholder="placeholder || ''"
             :disabled="disabled"
             v-model="card.value">
      <img class="bank-logo" :src="card.logo ? card.logo : 'https://s17.picofile.com/file/8422334526/exe.png'">
    </label>
  </div>
</template>

<script>
import {addSpace} from '@/assets/js/functions'

export default {
  name: 'customCardNumberInput',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    labelPlaceholder: {
      type: String,
      default: ''
    },
    labelPosition: {
      type: String,
      default: 'outline'
    },
    isEmpty: {
      type: Boolean,
      default: false
    },
    hasValidation: {
      type: Boolean,
      default: true
    },
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      card: {
        logo: '',
        value: '',
        isValid: false
      }
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))
      if (!styles.width) {
        // styles.width = '230px'
      }

      return styles
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    if (this.hasValidation) {
      this.validateValue()
    } else {
      this.card.isValid = true
    }
  },
  methods: {
    handleInputValue () {
      this.getBankLogo()
      this.addSpace()
      if (this.hasValidation) {
        this.validateValue()
      }
      this.$emit('input', {value: this.card.value.toString().replaceAll(' ', ''), isValid: this.card.isValid, logo: this.card.logo})
    },
    getBankLogo () {
      let logo = ''
      const number = this.card.value.replaceAll(' ', '').substr(0, 6)
      if (number === '603799') { logo = 'https://s16.picofile.com/file/8422331126/meli.png' }
      if (number === '589210') { logo = 'https://s16.picofile.com/file/8422331326/sepah.png' }
      if (number === '627961') { logo = 'https://s17.picofile.com/file/8422331292/sanatmadan.png' }
      if (number === '603770') { logo = 'https://s16.picofile.com/file/8422331084/keshavarsi.png' }
      if (number === '628023') { logo = 'https://s17.picofile.com/file/8422331100/maskan.png' }
      if (number === '627760') { logo = 'https://s17.picofile.com/file/8422331192/postbank.png' }
      if (number === '502908') { logo = 'https://s16.picofile.com/file/8422331392/tosehe.png' }
      if (number === '627412') { logo = 'https://s16.picofile.com/file/8422330984/eghtesad.png' }
      if (number === '622106') { logo = 'https://s17.picofile.com/file/8422331150/parsian.png' }
      if (number === '502229') { logo = 'https://s16.picofile.com/file/8422331184/pasargad.png' }
      if (number === '627488') { logo = 'https://s16.picofile.com/file/8422331076/karafarin.png' }
      if (number === '621986') { logo = 'https://s17.picofile.com/file/8422331276/saman.png' }
      if (number === '639346') { logo = 'https://s16.picofile.com/file/8422331368/sina.png' }
      if (number === '639607') { logo = 'https://s17.picofile.com/file/8422331300/sarmaye.png' }
      if (number === '502806') { logo = 'https://s17.picofile.com/file/8422331350/shahr.png' }
      if (number === '502938') { logo = 'https://s16.picofile.com/file/8422330976/day.png' }
      if (number === '603769') { logo = 'https://s17.picofile.com/file/8422331250/saderat.png' }
      if (number === '610433') { logo = 'https://s16.picofile.com/file/8422331134/mellat.png' }
      if (number === '627353') { logo = 'https://s16.picofile.com/file/8422331376/tejarat.png' }
      if (number === '589463') { logo = 'https://s17.picofile.com/file/8422331200/refah.png' }
      if (number === '627381') { logo = 'https://s16.picofile.com/file/8422330934/ansar.png' }
      if (number === '639370') { logo = 'https://s16.picofile.com/file/8422331118/mehreqtesad.png' }
      if (number === '639599') { logo = 'https://s16.picofile.com/file/8422331042/ghavamin.png' }
      if (number === '504172') { logo = 'https://s17.picofile.com/file/8422331226/resalat.png' }
      this.card.logo = logo
    },
    validateValue () {
      const code = this.card.value.replaceAll(' ', '')
      const L = code.length
      if (this.isEmpty && this.card.value.length === 0) {
        this.card.isValid = true
        return true
      }
      if (!this.isEmpty) {
        if (L < 16 || parseInt(code.substr(1, 10), 10) === 0 || parseInt(code.substr(10, 6), 10) === 0) {
          this.card.isValid = false
          return false
        }
      }
      // const c = parseInt(code.substr(15, 1), 10)
      let s = 0
      let k = 0, d = 0
      for (let i = 0; i < 16; i++) {
        k = (i % 2 === 0) ? 2 : 1
        d = parseInt(code.substr(i, 1), 10) * k
        s += (d > 9) ? d - 9 : d
      }
      this.card.isValid = ((s % 10) === 0)
    },
    addSpace () {
      let val = this.card.value
      const limit = 19

      val = val.toString()

      if (val[0] === '0') val = val.substr(1, val.length - 1)

      if (limit && val.length > limit) val = val.substr(0, limit)

      val = val.toString().replace(/\D/g, '')


      const arr = []
      if (val.substr(0, 4) !== undefined) arr.push(val.substr(0, 4))

      if (val.substr(4, 4) !== undefined) arr.push(val.substr(4, 4))

      if (val.substr(8, 4) !== undefined) arr.push(val.substr(8, 4))

      if (val.substr(12, 4) !== undefined) arr.push(val.substr(12, 4))

      let copy = ''
      arr.forEach((elm) => {
        if (elm) {
          if (copy) copy += ' '

          copy += elm
        }
      })

      this.card.value = copy
    },
    initValues () {
      this.card.value = addSpace(this.value.value)
      this.getBankLogo()
      if (this.hasValidation) {
        this.validateValue()
      }
    }
  },
  watch: {
    value: {
      handler () {
        this.initValues()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-card-number-input {
  position: relative;
  //margin: 10px 0;

  label {
    position: relative;
    display: block;
    padding: 6px 10px 6px 5px;
    border-radius: .5rem;
    transition: all .3s ease;

    &.is-not-valid {
      border-color: #b00000;
      color: #b00000;

      input {
        color: #b00000;
      }
    }

    span {
      position: absolute;
      padding: 0 5px;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -o-transition: all .3s ease;
      -ms-transition: all .3s ease;
      transition: all .3s ease;
      top: 7px;

      &.is-focus {
        font-size: 12px;
        top: -10px;
      }
    }

    input {
      width: calc(100% - 40px);
      background: transparent;
      border: none;
      outline: none !important;

      &.disabled {
        opacity: 0.75;
      }
    }

    img {
      width: 30px;
      height: 30px;
      display: block;
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}
</style>
